// @flow
import * as React from 'react';
import {
  Collapse,
  IconButton,
  Stack,
  styled,
  SwipeableDrawer,
  SxProps,
  Typography,
} from '@mui/material';
import { ArrowLineLeft } from '@phosphor-icons/react';
import { PropsWithChildren } from 'react';
import { useDeviceWidth } from '@coverright/utils';
import { SelectedPlansList } from '../..';
import { useQuoteId, useSavedPlansCount } from '@coverright/shared/contexts';
import { useMedicareQuoteQuery } from '@coverright/data-access/medicare';

type Props = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  sx?: SxProps;
  bottomWidget?: React.ReactNode;
};

export function PlanComparisonNavigationSidebar(props: Props) {
  const phone = useDeviceWidth('md');
  const Wrapper = phone ? MobileWrapper : DesktopWrapper;
  return (
    <Wrapper {...props}>
      <Content {...props} />
    </Wrapper>
  );
}

const MobileWrapper = ({
  open,
  children,
  onClose,
  onOpen,
}: PropsWithChildren<Props>) => (
  <StyledDrawer
    onOpen={onOpen}
    open={open}
    onClose={onClose}
    anchor={'left'}
    variant="temporary"
  >
    {children}
  </StyledDrawer>
);

const StyledDrawer = styled(SwipeableDrawer)({
  '& .MuiPaper-root': {
    height: 'calc(100% - 88px)',
    marginTop: 44,
    marginBottom: 44,
    overflowY: 'hidden',
    borderTopRightRadius: '12px',
    borderBottomRightRadius: '12px',
  },
  zIndex: 2000,
});

const DesktopWrapper = ({ open, children }: PropsWithChildren<Props>) => (
  <Collapse
    in={open}
    orientation={'horizontal'}
    sx={{ position: 'sticky', top: 0 }}
  >
    {children}
  </Collapse>
);

const Content = ({ sx, onClose, bottomWidget }: Props) => {
  const phone = useDeviceWidth('md');
  const quoteId  = useQuoteId();
  const { data } = useMedicareQuoteQuery({skip: !quoteId, variables: {id: quoteId} });
  const quote = data?.medicareQuote;
  const plansCount = useSavedPlansCount();

  return (
    <Stack
      sx={{
        borderRight: { md: '1px solid #C8CDD5' },
        maxWidth: 360,
        minWidth: 360,
        height: 'inherit',
        ...sx,
      }}
      justifyContent="space-between"
    >
      <Stack spacing={3} p={3}>
        <Stack
          direction={{ xs: 'row-reverse', md: 'row' }}
          spacing={1.5}
          alignItems={'center'}
          justifyContent={{ xs: 'flex-end', md: 'space-between' }}
        >
          <Typography variant={'h3'}>
            <b>
              {phone
                ? `${plansCount} saved plans`
                : `You have ${plansCount} saved plans`}
            </b>
          </Typography>
          <IconButton
            color={'outlined'}
            size={phone ? 'small' : 'medium'}
            onClick={onClose}
          >
            <ArrowLineLeft size={22} color={'#1C434F'} />
          </IconButton>
        </Stack>
        {(quoteId && quote?.clientId) && <SelectedPlansList
          quoteId={quoteId} clientId={quote?.clientId}
          style={{ height: `calc(100vh - ${phone ? 424 : 350}px)` }}
        />}
      </Stack>

      {bottomWidget}
    </Stack>
  );
};
