// @flow
import * as React from 'react';
import { getGapTooltipByTier, getInitialTooltipByTier, tooltips } from '@coverright/utils';
import { Stack, Typography } from '@mui/material';
import { mapToText, ParamName, Row, Section, Subtitle } from '../lib/lib';
import { ResponsiveTable, ResponsiveTableColumn, Tooltiped } from '@coverright/features';
import { MaPlanListQuery, PlanYear, SnpType } from '@coverright/data-access/types/medicare';
import { DrugTierOutput } from '@coverright/data-access/medicare';
import * as _ from 'lodash';
import { MaPlan } from '../../ma-plan-list/api/use-ma-plans';
import { V3InfoPanel } from '@coverright/ui/shared';

export function MaPlanDetailsDrugCoverageCosts({ plan }: { plan: MaPlan }) {
  const tiers = React.useMemo(() => {
    if (plan?.drugsTiers30Preferred?.initialCoverageDrugTiers.length) {
      return plan?.drugsTiers30Preferred?.initialCoverageDrugTiers || [];
    } else {
      return plan?.drugsTiers30Standard?.initialCoverageDrugTiers || [];
    }
  }, [plan]);

  const gapTiers = React.useMemo(() => {
    if (plan?.drugsTiers30Preferred?.coverageGapDrugTiers.length) {
      return plan?.drugsTiers30Preferred?.coverageGapDrugTiers || [];
    } else {
      return plan?.drugsTiers30Standard?.coverageGapDrugTiers || [];
    }
  }, [plan]);

  const isDSNP = plan.SNPType === SnpType.DSnp;

  return (
    <Section title={'Drug Coverage & Costs'}>

      {isDSNP && <V3InfoPanel
        sx={{ p: 2, mb: 2 }}
        showIcon={false}
        title={''}
        text={<Typography variant={'body2'}>
          Because you're on <b>Medicaid</b>, you are also eligible to receive discounted drug prices through Extra Help.
          The table below shows the current standardized copay amounts for recipients of Extra Help. The specific price
          you pay for your drugs depends on the level of Extra Help you are currently receiving.
        </Typography>}
      />}

      <Subtitle variant={'body2'}>Prescription Drug Costs</Subtitle>
      <Subtitle sx={{ bgcolor: '#E0F0F5' }} variant={'body2'}>
        Deductible phase
        <br />
        <Typography variant={'caption'}>
          You pay the full retail price of your drugs up until you meet the
          deductible level (if applicable).
        </Typography>
      </Subtitle>
      <Row
        title={
          <ParamName tooltip={tooltips.maPrescriptionDrugDeductible}>
            Deductible amount
          </ParamName>
        }
      >
        <Stack spacing={1}>
          {plan.prescriptionDrugDeductible.map(mapToText)}
        </Stack>
      </Row>

      {isDSNP && (
        <>
          <Subtitle sx={{ bgcolor: '#E0F0F5', mb: 2 }} variant={'body2'}>
            Initial Coverage Limit phase
            <br />
            <Typography variant={'caption'}>
              {plan.drugDetails?.initialCoverageDescription}
            </Typography>
          </Subtitle>
          <Row title={<ParamName>All tiers</ParamName>} >
            <Typography variant={'body2'}>
              <b>Generic drugs:</b><br />
              $0 copay; or<br />
              $4.90 copay<br /><br />

              <b>Branded drugs:</b><br />
              $0 copay; or<br />
              $12.15 copay<br /><br />

              (depending on your level of Extra Help)
            </Typography>
          </Row>
        </>
      )}

      {!!plan?.drugDetails?.initialCoverage?.length && (
        <>
          <Subtitle sx={{ bgcolor: '#E0F0F5', mb: 2 }} variant={'body2'}>
            Initial Coverage Limit phase
            <br />
            <Typography variant={'caption'}>
              {plan.drugDetails?.initialCoverageDescription}
            </Typography>
          </Subtitle>
          <ResponsiveTable
            sx={{
              tableLayout: { xs: 'fixed', sm: 'auto!important' },
              '& tr > td:first-of-type': { backgroundColor: '#FFF' },
            }}
            columns={getTableColumns()}
            rows={tiers.map((tier) =>
              [
                plan.drugsTiers30Preferred,
                plan.drugsTiers30Standard,
                plan.drugsTiers90Preferred,
                plan.drugsTiers90Standard,
              ].map((d) => getTier(tier.tier, d))
            )}
          />
        </>
      )}

      {plan.planYear === PlanYear.Year2024 && <>
        <Subtitle sx={{ bgcolor: '#E0F0F5', mb: 2 }} variant={'body2'}>
          Coverage Gap phase
          <br />
          <Typography variant={'caption'}>
            {plan.drugDetails?.coverageGapDescription}
          </Typography>
        </Subtitle>
        <ResponsiveTable
          sx={{
            tableLayout: 'auto!important',
            '& tr > td:first-of-type': { backgroundColor: '#FFF' },
          }}
          columns={getTableColumns()}
          footer={
            <tr>
              <td>
                <Typography className={'no-wrap'}>
                  <b>All other tiers</b>
                </Typography>
              </td>
              <td
                colSpan={4}
                style={{ textAlign: 'center', borderBottom: 'none' }}
              >
                25% of retail price
              </td>
            </tr>
          }
          rows={gapTiers.map((tier) =>
            [
              plan.drugsTiers30Preferred,
              plan.drugsTiers30Standard,
              plan.drugsTiers90Preferred,
              plan.drugsTiers90Standard,
            ].map((d) => getTier(tier.tier, d, 'coverageGapDrugTiers'))
          )}
        />
      </>}

      <Subtitle sx={{ bgcolor: '#E0F0F5' }} variant={'body2'}>
        Catastrophic Coverage phase
        <br />
        <Typography variant={'caption'}>
          {plan.drugDetails?.catastrophicCoverageDescription}
        </Typography>
      </Subtitle>
      {plan?.drugDetails?.catastrophicCoverage[0].items.map(
        (item, i, array) => (
          <Row
            key={item.title}
            title={
              <ParamName
                tooltip={getGapTooltipByTier(
                  [DrugTierOutput.Generic, DrugTierOutput.NonPreferredBrand][i]
                )}
              >
                {item.title}
              </ParamName>
            }
          >
            <Stack spacing={1}>{item.values.map(mapToText)}</Stack>
          </Row>
        )
      )}
    </Section>
  );
}

type DrugTiersOutput =
  MaPlanListQuery['plans']['data'][0]['drugsTiers30Preferred'];

type DrugTiersOutputPhase = 'initialCoverageDrugTiers' | 'coverageGapDrugTiers';

const getTier = (
  tier: DrugTierOutput,
  data?: DrugTiersOutput,
  phase: DrugTiersOutputPhase = 'initialCoverageDrugTiers'
): string => {
  return _.get(data, phase)?.find((t) => t.tier === tier)?.coverage || 'n.a';
};

const getTableColumns: () => ResponsiveTableColumn<string[]>[] = _.memoize(
  () => {
    const result: ResponsiveTableColumn<string[]>[] = [
      {
        title: '',
        value: (data, i) => (
          <Tooltiped tooltip={getInitialTooltipByTier(tiers[i])} sx={{ mr: 3 }}>
            <Typography sx={{whiteSpace: {xs: 'initial', sm: 'nowrap' } }}>
              <b>{tierNames[i]}</b>
            </Typography>
          </Tooltiped>
        ),
      },
      {
        title: 'Preferred Retail Pharmacy cost-sharing (30 day supply)',
        value: (data, i) => data[0],
      },
      {
        title: 'Standard Retail Pharmacy cost-sharing (30 day supply)',
        value: (data, i) => data[1],
      },
      {
        title: 'Preferred Mail Order cost-sharing (90 day supply)',
        value: (data, i) => data[2],
      },
      {
        title: 'Standard Mail Order cost-sharing (90 day supply)',
        value: (data, i) => data[3],
      },
    ];

    return result;
  }
);

const tiers = [
  DrugTierOutput.PreferredGeneric,
  DrugTierOutput.Generic,
  DrugTierOutput.PreferredBrand,
  DrugTierOutput.NonPreferredBrand,
  DrugTierOutput.Specialty,
];

const tierNames = [
  'Tier 1: Preferred generic drugs',
  'Tier 2: Generic drugs',
  'Tier 3: Preferred brand drugs',
  'Tier 4: Non-preferred drugs',
  'Tier 5: Specialty drugs',
];
