import React, { useState } from 'react';
import {
  Box, Button,
  Collapse,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography
} from '@mui/material';
import { logEvent } from '@coverright/shared/analytics';
import { useSavePharmacies } from '../lib/use-save-pharmacies';
import { DrugDeliveryTypeDto, PharmacyLocationDistanceOutput } from '@coverright/data-access/types/medicare';
import { PharmaciesModal } from '@coverright/features';
import { Prescription } from '@phosphor-icons/react';
import { DoctorsDrugsItem } from './doctors-drugs-item';
import { formatPharmacyName } from '@coverright/utils';
import { MedicareQuoteQuery } from '@coverright/data-access/medicare';
import { NonOptional } from '../../../index';


const PharmacyTab = ({quote}: {quote: NonOptional<MedicareQuoteQuery['medicareQuote']>}) => {
  const {preferredPharmacies, drugDeliveryType, zip} = quote;
  const savePharmacies = useSavePharmacies(quote);
  const [modalOpen, setModalOpen] = useState(false);

  const handleDeliveryTypeChange = (type: DrugDeliveryTypeDto) => {
    logEvent('Order type change', { orderType: type });
    savePharmacies(preferredPharmacies, type);
  }

  const handleModalSubmit = (paharmacies: PharmacyLocationDistanceOutput[]) => {
    setModalOpen(false);
    savePharmacies(paharmacies, drugDeliveryType!);
  };

  const handleDeleteClick = () => {
    savePharmacies([], drugDeliveryType!);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 1 }}>
      <PharmaciesModal
        zip={zip}
        values={preferredPharmacies}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={handleModalSubmit}
      />
      <Stack gap={3} direction={{ md: 'row' }} alignItems={{ md: 'flex-end' }} justifyContent={'space-between'}>
        <Stack sx={{width: 1}} spacing={.5}>
          <Typography variant={'h2'}>Add your pharmacy</Typography>
          <Typography color={'text.secondary'}>
            This will help find the best price for your drugs.<br/>
            Choose your preferred way of receiving your prescription drugs.
          </Typography>
        </Stack>

        <RadioGroup value={drugDeliveryType}
                    sx={{width: 1}}
                    onChange={e => handleDeliveryTypeChange(e.target.value as DrugDeliveryTypeDto)}>
          <Stack gap={1} sx={{width: 1}} direction={{ md: 'row' }}>
            <StyledRadio value={DrugDeliveryTypeDto.Pharmacy} sx={{border: '1px solid #C8CDD5'}}
                         control={<Radio />} label={'Retail pharmacy'} />
            <StyledRadio value={DrugDeliveryTypeDto.Mail} sx={{border: '1px solid #C8CDD5'}}
                         control={<Radio />} label={'Mail Order'} />
          </Stack>
        </RadioGroup>
      </Stack>

      <Collapse in={drugDeliveryType === DrugDeliveryTypeDto.Pharmacy}>
        <Divider />

        {!preferredPharmacies.length && <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', pt: '42px'}}>
          <Typography variant={'body2'}><b>No pharmacy added</b></Typography>
          <Typography variant={'body2'} color={'text.secondary'} sx={{maxWidth: 500, textAlign: 'center', mb: 3, mt: .5}}>
            Choosing a pharmacy helps us more accurately estimate drug costs. Well find your drug cost-shares depending on your pharmacy type selection.
          </Typography>

          <Button variant={'contained'} size={'large'} onClick={() => setModalOpen(true)}>
            Add pharmacy
          </Button>
        </Box>}

        {preferredPharmacies.length > 0 && (
          preferredPharmacies.map(pharmacy => (
            <DoctorsDrugsItem title={formatPharmacyName(pharmacy.name || '')}
                              icon={<Prescription size={24} color={'#216069'} />}
                              content={<Box flex={1}>
                                <Typography>{pharmacy.address}</Typography>
                              </Box>}
                              onEditClick={() => {setModalOpen(true);}}
                              onDeleteClick={handleDeleteClick}
                              key={pharmacy.npi} />
          ))
        )}
      </Collapse>

    </Box>
  );
};

export { PharmacyTab };


const StyledRadio = styled(FormControlLabel)(({theme}) => ({
  padding: '2px 16px 2px 6px',
  display: 'flex',
  flex: 1,
  borderRadius: '8px',
  alignItems: 'center',
  border: '1px solid #C8CDD5',
  height: 48,
  [theme.breakpoints.up('md')]: {
    maxWidth: 237,
  },
  width: '100%',
  margin: 0,
  '& .MuiFormControlLabel-label': {
    fontSize: 18,
    lineHeight: '32px',
    fontWeight: 500,
    marginLeft: 7,
    whiteSpace: 'nowrap',
  },
}));
