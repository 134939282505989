// @flow
import * as React from 'react';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { TitleRow } from '../../ma-plan-details/pdf/lib';
import { PropsWithChildren } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { PdfBenefit, styles } from '@coverright/shared/pdf';
import { toCurrency } from '@coverright/utils';
import { getDrugDiscount, getDrugOfferGetter } from '../lib/lib';
import { DrugDiscountBrand } from '@coverright/data-access/types/medicare';
import { Row, TableCell, TableHeader } from './lib';

type Props = {
  plan: IDrugCostBreakdownPlan;
};

export function DrugCostBreakdownPdfTemplateDrugsSummary({plan}: Props) {
  const getDrugOffer = getDrugOfferGetter(plan.discountedDrugs)
  const isCostPlusExists = plan.discountedDrugs?.some(
    (p) => p.discountBrand === DrugDiscountBrand.CostPlus
  );

  return (
    <>
      <TitleRow title={'Plan and drugs summary'} />

      <Row>
        <TableHeader>Drug</TableHeader>
        <TableHeader>Detail</TableHeader>
        <TableHeader>Covered?</TableHeader>
        <TableHeader>Yearly Plan</TableHeader>
        <TableHeader>Yearly Discount</TableHeader>
      </Row>

      {plan.drugPrices.map(price => (
        <Row key={price.packageDescription}>
          <TableCell>
            <Text style={[styles.text, {fontWeight: 'semibold'}]}>
              {price.packageDescription}
            </Text>
          </TableCell>
          <TableCell>
            <Text style={styles.text}>{price.isGeneric ? 'Generic' : 'Branded'}</Text>
            <Text style={styles.text}>{price.quoteQuantity}</Text>
          </TableCell>
          <TableCell>
            <PdfBenefit value={price.isCoveredByPlan}
                        label={price.isCoveredByPlan ? 'Yes' : 'No'} />
          </TableCell>
          <TableCell>
            <Text style={styles.text}>
              {toCurrency(getDrugOffer(price.rxcui)?.carrierCost, 2, 2)}
            </Text>
          </TableCell>
          <TableCell>
            {getDrugOffer(price.rxcui)?.discount > 0 && (
              <Text style={styles.text}>
                {getDrugDiscount(getDrugOffer(price.rxcui))}
              </Text>
            )}
            {getDrugOffer(price.rxcui)?.discount <= 0 && (
              <Text style={styles.text}>-</Text>
            )}
          </TableCell>
        </Row>
      ))}

      {isCostPlusExists && (
        <Text style={[styles.text, {color: '#666', marginTop: 8}]}>
          * There is a $5 shipping fee for orders from Cost Plus Drugs
          regardless of how many prescriptions you order. Also, you may be
          charged a pharmacy fee. The discounted price is an accurate estimate
          of your drugs' cost.
        </Text>
      )}
    </>
  );
}



