// @flow
import * as React from 'react';
import { useIsMAState, useIsWIState } from '@coverright/data-access/medicare';
import { Box, Card, MenuItem, styled, Typography } from '@mui/material';
import { SelectInput } from '@coverright/ui/inputs';
import { PlanCategoryDetails } from './plan-category-details';
import { useState } from 'react';
import { MedigapQuoteQuery } from '@coverright/data-access/types/medigap';
import { useSaveMgFilters } from '..';
import { planCategories, PlanCategory } from '../mg-filters/lib'; //todo remove dependency

interface Props {
  quote: MedigapQuoteQuery['medigapQuote'];
  collapsedByDefault?: boolean
}

export function PlanCategoryFilter({quote, collapsedByDefault}: Props) {
  const isMassachusetts = useIsMAState(quote?.medigapFilterState.zip);
  const isWisconsin = useIsWIState(quote?.medigapFilterState.zip);
  const save = useSaveMgFilters(quote);
  const [planCategory, setPlanCategory] = useState(
    quote?.medigapFilterState.planCategory || PlanCategory.MostPopular
  );

  if (isWisconsin || !quote) {
    return null;
  }

  if (isMassachusetts) {
    return (
      <Card>
        <Typography className={'fs-14 bold'}>
          Medicare Supplement Policies in Massachusetts
        </Typography>
        <Typography className={'fs-14 mt-10'}>
          If you live in Massachusetts, you have guaranteed issue rights to buy
          a Medicare Supplement policy, but the policies are not standardized as
          they are in other states. Instead, you can choose from a Medicare Core
          or Medicare Supplement 1 (if eligible) or 1A Plan.
        </Typography>
      </Card>
    );
  }

  const handleChange = (value: PlanCategory | 'all') => {
    setPlanCategory(value);
    const planNames = getPlansByCategory(value);
    save({ planNames, planCategory: value });
  };

  return (
    <div>
      <Wrapper>
        <Typography
          variant={'h3'}
          sx={{ lineHeight: 1, fontSize: { xs: 16, sm: 18 } }}
          color={'white.main'}
        >
          <b>Select a plan category:</b>
        </Typography>
        <StyledSelect
          label={''}
          hideTick
          validate={false}
          data-test={'category'}
          sx={{ width: 1, maxWidth: { sm: 280 } }}
          value={planCategory}
          onChange={(event) => {
            handleChange(event.target.value as PlanCategory);
          }}
        >
          {planCategories.map((cat, index) => (
            <MenuItem
              data-test={`category-${index}`}
              key={cat.name}
              value={cat.name}
            >
              {cat.name} ({cat.plans.join(', ')})
            </MenuItem>
          ))}
          <MenuItem data-test={`category-all`} value={'all'}>
            All plans
          </MenuItem>
        </StyledSelect>
      </Wrapper>
      <PlanCategoryDetails quote={quote} planCategory={planCategory} collapsedByDefault={collapsedByDefault} />
    </div>
  );
}

export const getPlansByCategory = (chosenCategory: PlanCategory | 'all') => {
  if (chosenCategory === 'all') {
    return [];
  } else {
    return planCategories.find((p) => p.name === chosenCategory)!.plans;
  }
};

const Wrapper = styled(Box)(({ theme }) => ({
  background: '#1c434f',
  boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.15)`,
  borderLeft: '10px solid #5ECB9E',
  padding: '8px 6px 8px 20px',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  gap: 80,
  [theme.breakpoints.down('md')]: {
    gap: 8,
    justifyContent: 'space-between',
    padding: '12px 20px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const StyledSelect = styled(SelectInput)({
  margin: 0,
  '& div.MuiOutlinedInput-root': {
    borderRadius: '8px',
    background: '#FFF',
  },
});
