import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Inter',
  fonts: [{
    src: 'https://fonts.cdnfonts.com/s/19795/Inter[slnt,wght].woff',
    fontWeight: 'normal',
  }, {
    src: 'https://fonts.cdnfonts.com/s/19795/Inter-Medium.woff',
    fontWeight: 'medium',
  }, {
    src: 'https://fonts.cdnfonts.com/s/19795/Inter-SemiBold.woff',
    fontWeight: 'semibold',
  }, {
    src: 'https://fonts.cdnfonts.com/s/19795/Inter-Bold.woff',
    fontWeight: 'bold',
  }]
});

Font.register({
  family: 'Epilogue',
  fonts: [{
    src: 'https://fonts.cdnfonts.com/s/46635/Epilogue[wght].woff',
    fontWeight: 'normal',
  }, {
    src: 'https://fonts.cdnfonts.com/s/46635/Epilogue-Medium.woff',
    fontWeight: 'medium',
  }, {
    src: 'https://fonts.cdnfonts.com/s/46635/Epilogue-SemiBold.woff',
    fontWeight: 'semibold',
  }]
});

export const styles = StyleSheet.create({
  tableTitleCell: {
    backgroundColor: '#EFEBE1',
    padding: '8px 0',
    minHeight: 32,
    fontWeight: 600,
  },
  text: {
    fontSize: '10px',
    lineHeight: '13px',
    fontFamily: 'Inter',
    textAlign: 'left'
  },
  planName: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'medium',
    fontFamily: 'Epilogue',
    textAlign: 'left'
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableCell: {
    display: 'flex',
    width: 260,
    padding: 8,
    borderBottom: '1px solid #e5e7eb'
  },
  firstCell: {
    borderRight: '1px solid #e5e7eb'
  }
})
