import {
  ComparablePlan,
  ComparablePlanType,
  PdpPlan,
  Plan,
} from '@coverright/data-access/types/medicare';
import * as React from 'react';
import { PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import _ from 'lodash';

export const AppStateContext = React.createContext<
  [AppState, (values: Partial<AppState>) => Promise<void>]
>([
  {
    stepsCompleted: [],
  },
  async () => {},
]);

export const AppStateContextProvider = (
  props: PropsWithChildren<{ quoteId?: string, userFlowRestUrl: string }>
) => {
  const [state, setState] = useState(
    JSON.parse(sessionStorage.getItem('appState') || '{}')
  );
  const prevQuoteId = useRef(props.quoteId)

  useEffect(() => {
    if (!prevQuoteId.current && props.quoteId) {
      prevQuoteId.current = props.quoteId;
      fetch(
        `${props.userFlowRestUrl}/getState/${props.quoteId}`
      ).then((data) => {
        if (data.status === 200) {
          return data.json();
        }
        return {state: {}}
      }).then(data => setState(data?.state || {}))
    }
  }, [props]);

  const save = async (values: Partial<AppState>) => {
    const prevState = { ...state };
    const newState = { ...state, ...values };

    if (!newState.abTesting && localStorage.getItem('abTest')) {
      newState.abTesting = JSON.parse(localStorage.getItem('abTest')!);
    }

    setState(newState);

    try {
      if (!props.quoteId) {
        throw new Error('Can\'t find a quote id' )
      }
      await saveStateToServer(props.userFlowRestUrl + '/saveState', props.quoteId, newState);
    } catch (err) {
      setState(prevState);
      enqueueSnackbar((err as Error).message, { variant: 'error' });
    }
  };

  const value: [AppState, (values: Partial<AppState>) => Promise<void>] = useMemo(() => ([
    state, save
  ]), [state]);

  return (
    <AppStateContext.Provider value={value}>
      {props.children}
    </AppStateContext.Provider>
  );
};

export const useAppContext = () => useContext(AppStateContext);

export function useSavedPlansCount() {
  const [{ maSavedPlans = [], mgSavedPlans = [], pdpSavedPlans = [] }] = useAppContext();
  return _.sumBy([maSavedPlans, mgSavedPlans, pdpSavedPlans], 'length');
}

const saveStateToServer = (url: string, quoteId: string, state: AppState) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      state,
      quoteId,
    }),
  });
};

export interface AppState {
  situation?: string;
  stepsCompleted: boolean[];
  marketplaceRedirect?: ComparablePlanType;
  maSavedPlans?: ComparablePlan[];
  mgSavedPlans?: ComparablePlan[];
  pdpSavedPlans?: ComparablePlan[];

  maVisited?: boolean;

  logoOverride?: {
    forbesHealth: boolean;
    benzinga: boolean;
    assetInsure: boolean;
    bellInsurance: boolean;
    rightsure: boolean;
    kroger: boolean;
  };

  //--- legacy ---
  videosApplied?: boolean;
  plansShown?: boolean;
  advisorCall?: boolean;
  appointmentType?: number;
  selectedCoverage?: 'MA' | 'MG' | 'PDP';
  existingCoverage?: 'MA' | 'PDP';
  maCarrier?: string;
  mgCarrier?: string;
  pdpCarrier?: string;
  maCurrentPlan?: string;
  pdpCurrentPlan?: string;
  quizSelected?: number;
  quizSuggested?: string;
  quizSuggestedText?: string;
  quizCompleted?: boolean;
  quizSelectedText?: string;

  videosCompleted?: string[];
  callScheduled?: boolean;
  greetingShown?: boolean;
  forbesHealthLogo?: boolean;
  quizAnswers?: boolean[];
  overrideMAEnrollment?: Plan;
  overridePDPEnrollment?: PdpPlan;
  abTesting?: {
    [key: string]: 'a' | 'b' | 'ab';
  };
  additionalInfo?: string[];
}
