import { getDeductible } from '@coverright/utils';
import * as React from 'react';
import { GetDefaultPlansYear } from '@coverright/utils';
import { Box } from '@mui/material';

export const omPlan: any = {
  bidId: 'omPlan',
  planName: 'Original Medicare Part A/Part B',
  monthlyPremium: (
    <>
      Part A: Typically $0.00
      <br />
      Part B: Typically {getDeductible('standardPartBMonthlyPremium')}
    </>
  ),
  drugsCost: 'Not covered',
  doctorsCost: 'Not covered',
  planType: 'Original Medicare',
  monthlyCost: '',
  quoteDrugsTiers: undefined,
  primaryCarePhysician: ['20% of the cost'],
  outOfPocketMaximum: ['No Limit'],
  physicianSpecialist: ['20% of the cost'],
  preventiveCare: ['$0 copay'],
  hospitalityDetails: {
    emergencyRoom: (
      <>
        You pay a copayment for each emergency department visit and a copayment
        for each hospital service.
        <Box sx={{ display: 'block', mt: 1.5 }} component={'span'}>
          You also pay 20% of the Medicare-approved amount for your doctor`s
          services, and the Part B deductible applies.
        </Box>
        <Box sx={{ display: 'block', mt: 1.5 }} component={'span'}>
          If your doctor admits you to the same hospital for a related condition
          within 3 days of your emergency department visit, you don`t pay the
          copayment because your visit is considered part of your inpatient
          stay.
        </Box>
      </>
    ),
    urgentServices: '20% of the cost after the Part B deductible',
    ambulanceServices: ['20% of the cost after the Part B deductible'],
  },
  outpatientHospitality: [
    `Diagnostic radiology services (such as MRIs, CT scans): 20% of the cost`,
    `Diagnostic tests and procedures: 20% of the cost`,
    `Lab services: You pay nothing`,
    `Outpatient x-rays: 20% of the cost`,
    `Therapeutic radiology services (such as radiation treatment for cancer): 20% of the cost after the Part B deductible`,
  ],
  outpatientSurgery: [
    `Outpatient hospital: 20% of the cost after the Part B deductible`,
    `Ambulatory surgical center: 20% of the cost after the Part B deductible`,
  ],
  outpatientRehabilitation: [
    `Cardiac rehabilitation services including exercise, education, and counseling: No cost`,
    `Intensive cardiac rehabiliation in a doctor’s office: 20% of the cost after the Part B deductible`,
    `Occupational therapy: 20% of the cost after the Part B deductible`,
    `Physical therapy and speech and language therapy: 20% of the cost after the Part B deductible`,
  ],
  mentalHealth: [
    `Annual depression screening: No cost`,
    `Visits to your doctor or other health care provider to diagnose or treat your condition: 20% of the cost after the Part B deductible`,
  ],
  homeHealth: [
    `You pay nothing if you meet the qualifying conditions.`,
    `Limitations and exclusions apply.`,
    `Medicare doesn't pay for:`,
    `<span class="list-item">24-hour-a-day care at your home</span>`,
    `<span class="list-item">Meals delivered to your home</span>`,
    `<span class="list-item">Homemaker services (like shopping, cleaning, and laundry) that aren’t related to your care plan</span>`,
    `<span class="list-item">Custodial or personal care that helps you with daily living activities (like bathing, dressing, or using the bathroom), when this is the only care you need</span>`,
  ],
  hospitalBenefits: [
    `<strong>${getDeductible(
      'inpatientHospitalDeductible',
      GetDefaultPlansYear()
    )}</strong> deductible for each benefit period`,
    `<span class="list-item"><strong>Days 1-60:</strong> $0 coinsurance for each benefit period</span>`,
    `<span class="list-item"><strong>Days 61-90:</strong> ${getDeductible(
      'dailyCoinsurance61_90',
      GetDefaultPlansYear()
    )} coinsurance per day of each benefit period</span>`,
    `<span class="list-item"><strong>Days 91 and beyond:</strong> ${getDeductible(
      'lifetimeReserveDays',
      GetDefaultPlansYear()
    )} coinsurance per each "lifetime reserve day" after day 90 for each benefit period (up to 60 days over your lifetime)</span>`,
    `<span class="list-item"><strong>Beyond lifetime reserve days:</strong> all costs</span>`,
  ],
  skilledNursing: [
    `<b>Days 1–20:</b> $0 for each benefit period.`,
    `<b>Days 21–100:</b> ${getDeductible(
      'skilledNursingFacility',
      GetDefaultPlansYear()
    )} coinsurance per day of each benefit period.`,
    `<b>Days 101 and beyond:</b> All costs.`,
  ],
  inNetworkDeductableAmount: (
    <>
      Part A: {getDeductible('inpatientHospitalDeductible', GetDefaultPlansYear())}
      <br />
      Part B: {getDeductible('annualPartBDeductible', GetDefaultPlansYear())}
    </>
  ),
  extraBenefits: {
    dental: false,
    drugCoverage: false,
    fitness: false,
    hearing: false,
    insulinSavings: false,
    overTheCounter: false,
    telehealth: false,
    transportation: false,
    vision: false,
    worldwideEmergency: false,
  },
  optionalSupplementalBenefitsItems: ['Not applicable'],
  dental: {
    benefits: [
      "Limited.  Medicare Part A (Hospital Insurance) will pay for certain dental services that you get when you're in a hospital.",
    ],
  },
  vision: {
    benefits: [
      'Limited. Exam to diagnose and treat diseases and conditions of the eye (including yearly glaucoma screening): 20% of the cost after the Part B deductible Eyeglasses or contact lenses after cataract surgery: 20% of the cost after the Part B deductible',
    ],
  },
  hearing: {
    benefits: [
      'Exam to diagnose and treat hearing and balance issues: 20% of the cost',
    ],
  },
  transportation: [
    `Limited. Diagnostic hearing and balance exams: 20% of the cost after the Part B deductible`,
  ],
  otherDefinedSupplemental: {
    fitnessBenefit: ['No'],
  },
  meal: ['No'],
  oTC: ['No'],
  telehealth: ['20% of the cost  after the Part B deductible'],
};

export const isOriginalMedicare = (plan: { planType?: string | null }) =>
  plan.planType === 'Original Medicare';
