// @flow
import * as React from 'react';
import { DrugPriceOutput, IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { Page, Document, Text, View } from '@react-pdf/renderer';
import { DrugCostBreakdownPdfTemplateHeader as Header } from './drug-cost-breakdown-pdf-template-header';
import { DrugCostBreakdownPdfTemplateDrugsSummary as DrugsSummary } from './drug-cost-breakdown-pdf-template-drugs-summary';
import {
  DrugCostBreakdownPdfTemplateDetailedCostCalculation as DetailedCostCalculation
} from './drug-cost-breakdown-pdf-template-detailed-cost-calculation';
import { DrugCostBreakdownPdfTemplateCoveragePhases as CoveragePhases } from './drug-cost-breakdown-pdf-template-coverage-phases';
import { styles } from '@coverright/shared/pdf';
import { RegulatoryVariable } from '@coverright/data-access/types/medicare';
import { DrugCostBreakdownPdfTemplateSummaryByMonth as SummaryByMonth } from './drug-cost-breakdown-pdf-template-summary-by-month';
import {
  DrugCostBreakdownPdfTemplateYearlyEstimationCoverage as YearlyEstimationCoverage
} from './drug-cost-breakdown-pdf-template-yearly-estimation-coverage';

type Props = {
  plan: IDrugCostBreakdownPlan;
  drugPrices: DrugPriceOutput[];
  regulatory?: RegulatoryVariable;
  onRender?: () => void
};

export function DrugCostBreakdownPdfTemplate({plan, onRender, drugPrices, regulatory}: Props) {
  return (
    <Document title={plan.planName || 'CoverRight'}
              onRender={onRender}
              author={'CoverRight'} >
      <Page size={'LETTER'} orientation={'portrait'} wrap style={{padding: 8}}>
        <Header plan={plan} />
        <YearlyEstimationCoverage plan={plan} />
        <DrugsSummary plan={plan} />
        <DetailedCostCalculation drugPrices={drugPrices} />
        <CoveragePhases drugPrices={drugPrices} />

        <View style={{display: 'flex', flexDirection: 'row', gap: 16, padding: '16px 8px'}}>
          <View style={{width: '50%'}}>
            <Text style={[styles.text, {fontWeight: 'semibold'}]}>Deductible</Text>
            <Text style={[styles.text, {color: '#666666'}]}>Estimated full retail price</Text>

            <Text style={[styles.text, {fontWeight: 'semibold', marginTop: 8}]}>Catastrophic coverage</Text>
            <Text style={[styles.text, {color: '#666666'}]}>
              Greater of {regulatory?.catastrophicCoinsurance || 0}% or $
              {regulatory?.catastrophicCopayGeneric || 0} (generics) / $
              {regulatory?.catastrophicCopayBranded || 0} (branded)
            </Text>
          </View>
          <View style={{width: '50%'}}>
            <Text style={[styles.text, {fontWeight: 'semibold'}]}>Initial coverage</Text>
            <Text style={[styles.text, {color: '#666666'}]}>Minimum of retail price and plan copay/ coinsurance (based on tier)</Text>
          </View>
        </View>

        <SummaryByMonth plan={plan} drugPrices={drugPrices} />

        <View style={{paddingVertical: 4, right: 20, bottom: 4, backgroundColor: '#FFF', position: 'absolute'}} fixed>
          <Text render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )} style={{fontSize: 8, opacity: .7}} />
        </View>
      </Page>
    </Document>
  );
}
