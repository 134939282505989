import { getToken } from '@coverright/shared/keycloak';
import { InMemoryCacheConfig } from '@apollo/client';
import { getApolloClient } from './ApolloClient';

const inMemoryCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    MedigapPlanOutput: {
      keyFields: ['key'],
    },
    DrugOutput: {
      keyFields: ['rxcui', 'packRxcui'],
      fields: {
        quantity: (existing) => {
          if (typeof existing === 'number') {
            return existing.toFixed(2)
          }
          return existing;
        }
      }
    },
    PharmacyLocationDistanceOutput: {
      keyFields: ['npi'],
    },
    PdpPlan: {
      keyFields: ['bidId', 'planYear'],
    },
    ProviderOutput: {
      keyFields: ['npi'],
    },
    Plan: {
      keyFields: ['bidId', 'planYear'],
      fields: {
        prescriptionDrugDeductible: (existing, { readField }) => {
          const drugDetails: any = readField('drugDetails');
          if (
            !drugDetails ||
            (!drugDetails.mrxAltDedAmount && !drugDetails.mrxAltNoDedTier)
          ) {
            return ['No deductible'];
          }
          return [
            drugDetails.mrxAltDedAmount || '',
            drugDetails.mrxAltNoDedTier || '',
          ];
        },
      },
    },
    Query: {
      fields: {
        PdpPlans: {
          keyArgs: ['filter', 'sort'],
          merge(existing, incoming, { args }) {
            const page = args?.page || { page: 0, size: 8 };
            const offset = page.size * page.page;

            const merged = existing?.data ? existing.data.slice(0) : [];
            for (let i = 0; i < incoming.data.length; i++) {
              merged[offset + i] = incoming.data[i];
            }
            return {
              ...incoming,
              data: merged,
            };
          },
        },
        plans: {
          keyArgs: ['filter', 'sort'],
          merge(existing, incoming, { args }) {
            const page = args?.page || { page: 0, size: 8 };
            const offset = page.size * page.page;

            const merged = existing?.data ? existing.data.slice(0) : [];
            for (let i = 0; i < incoming.data.length; i++) {
              merged[offset + i] = incoming.data[i];
            }
            return {
              ...incoming,
              data: merged,
            };
          },
        },
      },
    },
  },
};

const { client: mainBackendApolloClient, cache: mainBackendApolloCache, preloadQuery } = getApolloClient(
  getToken,
  process.env.NX_GRAPHQL,
  inMemoryCacheConfig
);

export { mainBackendApolloClient, mainBackendApolloCache, preloadQuery };
