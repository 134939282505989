import { MedigapPlanName } from '@coverright/data-access/types/medigap';

export const getMedgapPlanLabel = (planName?: string | null) => {
  switch (planName) {
    case 'HDF':
      return 'Plan F (High Deductible)';
    case 'HDG':
      return 'Plan G (High Deductible)';
    case 'MA_SUPP1':
      return 'Supplement 1';
    case 'MA_SUPP1A':
      return 'Supplement 1A';
    case 'MA_CORE':
      return 'Core plan';
    case 'WI_75%':
      return 'Medigap 75% Cost Sharing Plan';
    case 'WI_50%':
      return 'Medigap 50% Cost Sharing Plan';
    case 'WI_BASE':
      return 'Medigap Basic Plan';
    case 'WI_HDED':
      return 'Medigap High Deductible Plan';
    default:
      return 'Plan ' + planName;
  }
};

export enum PlanCategory {
  MostPopular = 'Most Popular',
  CoreBenefits = 'Core benefits',
  //  Comprehensive = 'Comprehensive',
  CostShare = 'Cost-share',
  HighDeductible = 'High deductible',
}

export type PlanCategoryDescription = {
  name: PlanCategory;
  plans: MedigapPlanName[];
};

export const planCategories: PlanCategoryDescription[] = [
  {
    name: PlanCategory.MostPopular,
    plans: [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N],
  },
  //  {name: PlanCategory.Comprehensive, plans: [MedigapPlanName.C, MedigapPlanName.D, MedigapPlanName.F, MedigapPlanName.G]},
  {
    name: PlanCategory.HighDeductible,
    plans: [MedigapPlanName.Hdf, MedigapPlanName.Hdg],
  },
  {name: PlanCategory.CostShare, plans: [MedigapPlanName.K, MedigapPlanName.L, MedigapPlanName.M]},
  {
    name: PlanCategory.CoreBenefits,
    plans: [MedigapPlanName.A, MedigapPlanName.B],
  },
];
