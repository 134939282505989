// @flow
import * as React from 'react';
import { forwardRef, useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  MenuItem,
  styled,
  Tooltip,
} from '@mui/material';
import { ComparablePlanType } from '@coverright/data-access/medicare';
import _ from 'lodash';
import { SelectInput } from '@coverright/ui/inputs';
import { useAppContext } from '@coverright/shared/contexts';
import { useDeviceWidth } from '@coverright/utils';

type Props = {
  onChange: (value?: ComparablePlanType) => void;
};

export function PlanComparisonTypeSelector({ onChange }: Props) {
  const phone = useDeviceWidth('md');
  const [{ maSavedPlans = [], mgSavedPlans = [], pdpSavedPlans = [] }] =
    useAppContext();
  const allButtonEnabled =
    _.sumBy([maSavedPlans, mgSavedPlans, pdpSavedPlans], (v) =>
      v.length > 0 ? 1 : 0
    ) > 1;

  const findTypeWithExistingPlans = (): ComparablePlanType | undefined => {
    switch (true) {
      case maSavedPlans.length > 0:
        return ComparablePlanType.Ma;
      case mgSavedPlans.length > 0:
        return ComparablePlanType.Mg;
      case pdpSavedPlans.length > 0:
        return ComparablePlanType.Pdp;
      default:
        return undefined;
    }
  };

  const [type, setType] = useState<ComparablePlanType | undefined>(() => {
    if (allButtonEnabled) {
      return undefined;
    }
    onChange(findTypeWithExistingPlans());
    return findTypeWithExistingPlans();
  });

  const handleChange = (value?: ComparablePlanType) => {
    setType(value);
    onChange(value);
  };

  useEffect(() => {
    //change type if there is no plans for existing
    if (!maSavedPlans.length && type === ComparablePlanType.Ma) {
      handleChange(findTypeWithExistingPlans());
    }
    if (!mgSavedPlans.length && type === ComparablePlanType.Mg) {
      handleChange(findTypeWithExistingPlans());
    }
    if (!pdpSavedPlans.length && type === ComparablePlanType.Pdp) {
      handleChange(findTypeWithExistingPlans());
    }
    if (!allButtonEnabled && type === undefined) {
      handleChange(findTypeWithExistingPlans());
    }
  }, [maSavedPlans, mgSavedPlans, pdpSavedPlans, type, allButtonEnabled]);

  const Component = phone ? Mobile : Desktop;
  return (
    <Component
      type={type}
      onChange={handleChange}
      allButtonEnabled={allButtonEnabled}
    />
  );
}

type InnerProps = {
  allButtonEnabled: boolean;
  type?: ComparablePlanType;
  onChange: (value?: ComparablePlanType) => void;
};

function Mobile({ onChange, type, allButtonEnabled }: InnerProps) {
  const [{ maSavedPlans = [], mgSavedPlans = [], pdpSavedPlans = [] }] =
    useAppContext();
  const handleChange = (value?: ComparablePlanType) => {
    onChange(value);
  };

  let bgcolor: string;

  switch (type) {
    case ComparablePlanType.Ma:
      bgcolor = '#FCF2C9';
      break;
    case ComparablePlanType.Mg:
      bgcolor = '#E0F0F5';
      break;
    case ComparablePlanType.Pdp:
      bgcolor = '#EFEBE1';
      break;
    default:
      bgcolor = '#F3F4F6';
  }

  return (
    <StyledSelect
      value={type}
      hideTick
      sx={{ bgcolor }}
      validate={false}
      onChange={(e) => handleChange(e.target.value as ComparablePlanType)}
    >
      <MenuItem disabled={!allButtonEnabled} value={undefined}>All saved plans</MenuItem>
      <MenuItem disabled={!mgSavedPlans.length} value={ComparablePlanType.Mg}>Medicare Supplement</MenuItem>
      <MenuItem disabled={!pdpSavedPlans.length} value={ComparablePlanType.Pdp}>Standalone Drug Plans</MenuItem>
      <MenuItem disabled={!maSavedPlans.length} value={ComparablePlanType.Ma}>Medicare Advantage</MenuItem>
    </StyledSelect>
  );
}

const StyledSelect = styled(SelectInput)({
  '& .MuiOutlinedInput-input.MuiSelect-select': {
    fontWeight: 600,
    fontFamily: 'Epilogue',
    padding: '16px 24px 16px 20px!important',
  },
  '& .MuiSelect-icon': {
    right: 28,
  },
  '& fieldset.MuiOutlinedInput-notchedOutline': {
    border: 'none!important',
    borderRadius: '0!important',
  },
});

function Desktop({ onChange, type, allButtonEnabled }: InnerProps) {
  const [{ maSavedPlans = [], mgSavedPlans = [], pdpSavedPlans = [] }] =
    useAppContext();
  return (
    <Box
      sx={{
        bgcolor: '#F3F4F6',
        p: '6px',
        borderRadius: '8px',
        width: 'fit-content',
      }}
    >
      <Tooltip
        title={
          !allButtonEnabled
            ? 'You have to select plans with different types to enable this option'
            : ''
        }
      >
        <Box sx={{ display: 'inline-flex' }}>
          <StyledButton
            active={!type}
            disabled={!allButtonEnabled}
            onClick={() => onChange(undefined)}
          >
            All
          </StyledButton>
        </Box>
      </Tooltip>
      <Tooltip
        title={
          !mgSavedPlans.length
            ? 'You have to select some Medicare Supplement plans to enable this option'
            : ''
        }
      >
        <Box sx={{ display: 'inline-flex' }}>
          <StyledButton
            active={type === ComparablePlanType.Mg}
            disabled={!mgSavedPlans.length}
            onClick={() => onChange(ComparablePlanType.Mg)}
          >
            Medicare Supplement
          </StyledButton>
        </Box>
      </Tooltip>
      <Tooltip
        title={
          !pdpSavedPlans.length
            ? 'You have to select some Standalone Drug plans to enable this option'
            : ''
        }
      >
        <Box sx={{ display: 'inline-flex' }}>
          <StyledButton
            active={type === ComparablePlanType.Pdp}
            disabled={!pdpSavedPlans.length}
            onClick={() => onChange(ComparablePlanType.Pdp)}
          >
            Standalone Drug Plans
          </StyledButton>
        </Box>
      </Tooltip>
      <Tooltip
        title={
          !maSavedPlans.length
            ? 'You have to select some Medicare Advantage plans to enable this option'
            : ''
        }
      >
        <Box sx={{ display: 'inline-flex' }}>
          <StyledButton
            active={type === ComparablePlanType.Ma}
            disabled={!maSavedPlans.length}
            onClick={() => onChange(ComparablePlanType.Ma)}
          >
            Medicare Advantage
          </StyledButton>
        </Box>
      </Tooltip>
    </Box>
  );
}

type StyledButtonProps = ButtonProps & { active: boolean };

const StyledButton = forwardRef<HTMLButtonElement | null, StyledButtonProps>(
  ({ children, active, ...rest }: StyledButtonProps, ref) => (
    <Button
      variant={active ? 'contained' : 'text'}
      ref={ref}
      size={'small'}
      sx={{
        boxShadow: active ? `0px 2px 6px 0px #0000001A` : 'none',
        borderRadius: '4px',
        fontWeight: 500,
        minWidth: 0,
        px: 1.5,
      }}
      color={active ? 'white' : 'primary'}
      {...rest}
    >
      {children}
    </Button>
  )
);
