// @flow
import * as React from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { CompanyLogo, StarRating } from '@coverright/ui/shared';
import { getCompanyLogo, GetDefaultPlansYear, useDeviceWidth } from '@coverright/utils';
import { MaSavePlanButton } from '../../../ma-plan-list/ma-plan-list-item/ui/ma-save-plan-button';
import { MaPlanDetailsHeaderPrices as Prices } from './ma-plan-details-header-prices';
import { MaPlan } from '../../../ma-plan-list/api/use-ma-plans';
import { MaDrugCostBreakdownButton } from '../../..';
import { useQuoteId } from '@coverright/shared/contexts';

export function MaPlanDetailsHeader({ plan }: { plan: MaPlan }) {
  const phone = useDeviceWidth('sm');
  const Component = phone ? Mobile : Desktop;
  return <Component plan={plan} />;
}

const Mobile = ({ plan }: { plan: MaPlan }) => (
  <Wrapper>
    <Stack spacing={1} justifyContent={'space-between'} direction={'row'}>
      <StarRating plan={plan} />
      <CompanyLogo
        sx={{ backgroundPosition: 'center' }}
        url={getCompanyLogo(plan.parentOrgName, plan.orgName)}
      />
    </Stack>
    <Typography sx={{ mt: 1 }} variant={'h2'} color={'text.primary'}>
      <b>{plan.planName}</b>
    </Typography>
    <Prices plan={plan} />
  </Wrapper>
);

const Desktop = ({ plan }: { plan: MaPlan }) => {
  const quoteId = useQuoteId();
  const showDrugCostButton =
    typeof plan.monthlyCost === 'string' &&
    !!plan.drugsCoverage?.length &&
    !plan.missingFormularyInAep;

  return <Wrapper>
    <Stack spacing={1} flexGrow={1} justifyContent={'space-between'}>
      <Stack spacing={2} justifyContent={'space-between'} direction={'row'}>
        <div>
          <StarRating plan={plan} />
          <Typography sx={{ mt: 1 }} variant={'h3'} color={'text.primary'}>
            <b>{plan.planName}</b>
          </Typography>
        </div>
      </Stack>
      <Stack spacing={2} justifyContent={'space-between'} direction={'row'}>
        {showDrugCostButton && <MaDrugCostBreakdownButton
          bidId={plan.bidId}
          variant={'outlined'}
          size={'large'}
          quoteId={quoteId}
          planYear={plan.planYear || GetDefaultPlansYear()}
        >
          See monthly drug cost
        </MaDrugCostBreakdownButton>}
        <MaSavePlanButton size={'large'} plan={plan} />
      </Stack>
    </Stack>

    <Prices plan={plan} />
  </Wrapper>
};

const Wrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  display: 'flex',
  border: '1px solid #E5E7EB',
  padding: 16,
  borderRadius: '8px',
  gap: 16,
}));
