import React from 'react';
import { Box, ThemeProvider } from '@mui/material';
import { V3Theme } from '@coverright/ui/themes';
import { delegate, getPdpQuoteId, getQuoteId } from '@coverright/utils';
import { ApolloProvider } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Cookies } from 'react-cookie';
import {
  client, enrollmentClient,
  setErrorHandler
} from '@coverright/data-access/apollo-clients';
import {
  PdpQuoteProvider,
  QuoteProvider,
  withAppContextProvider,
} from '@coverright/shared/contexts';
import {
  useLogEvent,
  useTrackAppointment,
} from '@coverright/shared/analytics';
import * as _ from 'lodash';
import { CloseModal } from '@coverright/features';
import Router from './router';
import { withDrugOffersContext } from './lib';
import { useLocation } from 'react-router-dom';
import { Layout } from '@coverright/shared/ui';
import { AppBar } from './components/app-bar';

const cookie = new Cookies();

if (typeof window !== 'undefined') {
  // Check if we're running in the browser.
  const urlParams = new URLSearchParams(window.location.search);
  if (
    !sessionStorage.getItem('utm_source') ||
    sessionStorage.getItem('utm_source') === 'null'
  ) {
    if (urlParams.has('utm_source')) {
      sessionStorage.setItem(
        'utm_source',
        urlParams.get('utm_source') as string
      );
    } else if (cookie.get('__opix_utm')) {
      const utm_source = cookie.get('__opix_utm').utm_source;
      if (utm_source) {
        sessionStorage.setItem('utm_source', utm_source);
      }
    }
  }
}

function App() {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const logEvent = useLogEvent();
  useTrackAppointment(enrollmentClient);

  React.useEffect(() => {
    delegate(document, 'click', 'button', function (event) {
      logEvent('click', {
        element_title: event.target.textContent,
        element_id: event.target.id,
      });
    });
  }, []);

  // todo refactor this!!!!!
  setErrorHandler(({ graphQLErrors, networkError }: any) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path, extensions }: any) => {
        console.error(
          `Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}`
        );
        enqueueSnackbar('Network error, please try again later', {
          variant: 'error',
        });
      });
    }
  });

  React.useEffect(() => {
    if (location.pathname) {
      const pageName = _.startCase(_.last(location.pathname.split('/')));
      logEvent('page_view', { page_title: pageName });
    }
  }, [location.pathname]);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={V3Theme}>
        <QuoteProvider quoteId={getQuoteId()}>
          <PdpQuoteProvider quoteId={getPdpQuoteId()}>
            <CloseModal />
            <Layout slots={{ appBar: <AppBar /> }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  mt: '144px',
                  mb: '93px',
                }}
              >
                <Router />
              </Box>
            </Layout>
          </PdpQuoteProvider>
        </QuoteProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default _.flowRight([
  withAppContextProvider,
  withDrugOffersContext,
])(App);
