import { Box, ButtonBase, Collapse, styled, Typography } from '@mui/material';
import { convertBold, convertList } from '@coverright/utils';
import { PropsWithChildren } from 'react';
import * as React from 'react';
import { CaretUp } from '@phosphor-icons/react';
import { Tooltiped } from '@coverright/features';

export const mapToText = (text: string, i: number) => (
  <Typography
    key={text + i}
    component={'p'}
    variant={'body2'}
    dangerouslySetInnerHTML={{ __html: convertList(convertBold(text)) }}
  />
);

export const ParamName = ({
  tooltip,
  children,
}: PropsWithChildren<{ tooltip?: React.ReactNode }>) => (
  <Tooltiped tooltip={tooltip || ''}>
    <Typography variant={'body2'}>
      <b>{children}</b>
    </Typography>
  </Tooltiped>
);

export const Row = ({
  title,
  children,
  id,
}: PropsWithChildren<{ title: React.ReactNode; id?: string }>) => (
  <Box
    id={id}
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      gap: { sm: 2 },
      my: { xs: 2, sm: 0 },
    }}
  >
    <Box flex={{ sm: 2 }} sx={{ p: { sm: 2 }, mb: { xs: 0.5, sm: 0 } }}>
      {title}
    </Box>
    <Box flex={{ sm: 3 }} sx={{ p: { sm: 2 } }}>
      {children}
    </Box>
  </Box>
);

export const Subtitle = styled(Typography)({
  backgroundColor: '#CBE4EC',
  fontWeight: 600,
  padding: '8px 24px',
  color: '#1C434F',
});

export const Section = (props: PropsWithChildren<{ title: string }>) => {
  const [expanded, setExpanded] = React.useState(true);

  return (
    <>
      <ButtonBase
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid #E5E7EB',
          alignItems: 'center',
          py: 2,
          pr: 2,
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Typography variant={'h3'}>
          <b>{props.title}</b>
        </Typography>
        <Box
          sx={{
            transform: expanded ? 'rotate(-180deg) !important' : 'rotate(0)',
            transition: 'all 0.3s',
          }}
        >
          <CaretUp size={18} color={'#1C434F'} />
        </Box>
      </ButtonBase>

      <Collapse in={expanded}>{props.children}</Collapse>
    </>
  );
};
