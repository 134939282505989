// @flow
import * as React from 'react';
import { Box, Button, Chip, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useDeviceWidth } from '@coverright/utils';
import { MedicareQuoteQuery } from '@coverright/data-access/medicare';
import { NonOptional } from '../../../index';

type Props = {
  tab: number;
  onChange: (tab: number) => void;
  quote: NonOptional<MedicareQuoteQuery['medicareQuote']>;
};

export function DoctorsDrugsTabSelector({tab, onChange, quote}: Props) {
  const phone = useDeviceWidth('sm');

  return (
    <Box
      sx={{
        bgcolor: { xs: '#E5E7EB' },
        p: 1,
        borderRadius: '8px',
        width: 1,
        display: 'flex',
        gap: 1
      }}
      id={'doctors-drugs-tabs'}
    >
      <StyledButton id={'doctors'} active={tab === 0} onClick={() => onChange(0)}>
        {phone ? 'Doctors' : 'Your doctors'}
        {!phone && <StyledChip size='small' variant='filled'
                               sx={{backgroundColor: tab === 0 && quote?.preferredDoctors?.length > 0 ? 'primary.100' : 'grey.200'}}
                               label={`${quote?.preferredDoctors?.length || 0} ADDED`}/>}
      </StyledButton>
      <StyledButton id={'drugs'} active={tab === 1} onClick={() => onChange(1)}>
        {phone ? 'Drugs' : 'Your drugs'}
        {!phone && <StyledChip size='small' variant='filled'
                               sx={{backgroundColor: tab === 1 && quote?.preferredDrugs?.length > 0 ? 'primary.100' : 'grey.200'}}
                               label={`${quote?.preferredDrugs?.length || 0} ADDED`}/>}
      </StyledButton>
      <StyledButton id={'pharmacy'} active={tab === 2} onClick={() => onChange(2)}>
        {phone ? 'Pharmacy' : 'Your pharmacy'}
        {!phone && <StyledChip size='small' variant='filled'
                               sx={{backgroundColor: tab === 2 && quote?.preferredPharmacies?.length > 0 ? 'primary.100' : 'grey.200'}}
                               label={`${quote?.preferredPharmacies?.length || 0} ADDED`}/>}
      </StyledButton>
    </Box>
  );
}

const StyledButton = ({
                        children,
                        active,
                        onClick,
                        id
                      }: PropsWithChildren<{ id: string, active: boolean; onClick: () => void }>) => (
  <Button
    id={id}
    variant={active ? 'contained' : 'text'}
    onClick={onClick}
    sx={{
      display: 'flex',
      gap: 1.5,
      width: 1,
      boxShadow: active ? `0px 2px 6px 0px #0000001A` : 'none',
      borderRadius: '8px',
    }}
    color={active ? 'white' : 'primary'}
  >
    {children}
  </Button>
);

const StyledChip = styled(Chip)({
  borderRadius: '6px',
  fontSize: 12,
  lineHeight: 1,
  letterSpacing: .07,
  fontFamily: 'Epilogue',
  height: 19,
  paddingTop: 4,
})


