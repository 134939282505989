import { PropsWithChildren } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { styles } from '@coverright/shared/pdf';
import * as React from 'react';

export const TableCell = ({children, style = {}}: PropsWithChildren<{style?: any}>) => (
  <View style={[{padding: 8, width: '20%', borderBottom: '1px solid #E8ECED'}, style]}>
    {children}
  </View>
)

export const TableHeader = ({children, style = {}}: PropsWithChildren<{style?: any}>) => (
  <View style={[{padding: 8, backgroundColor: '#F0F7FA', width: '20%'}, style]}>
    <Text style={[styles.text, {fontWeight: 'semibold'}]}>{children}</Text>
  </View>
)

export const Row = ({children, style = {}}: PropsWithChildren<{style?: any}>) => (
  <View style={[{display: 'flex', flexDirection: 'row'}, style]} wrap={false}>
    {children}
  </View>
)
