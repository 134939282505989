import { StyleSheet, Text, View } from '@react-pdf/renderer';
import * as React from 'react';
import { styles } from '@coverright/shared/pdf';
import { PropsWithChildren } from 'react';

export const TitleRow = ({ title }: { title: string }) => (
  <View style={{padding: '16px 0 8px'}} wrap={false}>
    <Text style={localStyles.title}>{title}</Text>
  </View>
)

export const Subtitle1Row = ({ title }: { title: string }) => (
  <View style={{color: '#1C434F', backgroundColor: '#cbe4ec', padding: '8px 16px'}} wrap={false}>
    <Text style={[styles.text, {fontSize: '11px', fontWeight: 'semibold'}]}>{title}</Text>
  </View>
)

export const Subtitle2Row = ({ title, subtitle }: { title: string, subtitle?: string }) => (
  <View style={{color: '#1C434F', backgroundColor: '#E0F0F5', padding: '8px 16px'}} wrap={false}>
    <Text style={[styles.text, {fontSize: '11px', fontWeight: 'semibold'}]}>{title}</Text>
    {subtitle && <Text style={[styles.text, { fontSize: '9px', marginTop: 4 }]}>{subtitle}</Text>}
  </View>
)

export const Row = ({title, children, wrap = false}: PropsWithChildren<{title: React.ReactNode, wrap?: boolean}>) => (
  <View style={localStyles.row} wrap={wrap}>
    <View style={{flex: 2}}>
      <Text style={[styles.text, {fontWeight: 'semibold'}]}>{title}</Text>
    </View>
    <View style={{flex: 3}}>
      {children}
    </View>
  </View>
)

const localStyles = StyleSheet.create({
  row: {
    padding: '8px',
    display: 'flex',
    flexDirection: 'row',
    gap: 16
  },
  title: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'semibold',
    fontFamily: 'Epilogue',
    textAlign: 'left',
  },
})
