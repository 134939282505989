// @flow
import * as React from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { tooltips } from '@coverright/utils';
import { MaPlan } from '../../../ma-plan-list/api/use-ma-plans';
import { Tooltiped } from '@coverright/features';

export function MaPlanDetailsHeaderPrices({ plan }: { plan: MaPlan }) {
  return (
    <Wrapper>
      <Stack
        direction={{ xs: 'row', sm: 'column' }}
        spacing={{ xs: 2, md: 0 }}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: { xs: 34, md: 40 },
            lineHeight: 1,
            fontWeight: 700,
          }}
        >
          {plan.monthlyCost || 'N/A'}
        </Typography>
        <Tooltiped
          sx={{ alignSelf: 'center' }}
          tooltip={tooltips.maMonthlyCost}
        >
          <Typography
            variant={'body2'}
            sx={{ mt: { md: 1 }, textAlign: { xs: 'end', md: 'start' } }}
          >
            <b>Est. Avg. total cost /mo</b>
          </Typography>
        </Tooltiped>
      </Stack>

      <Stack mt={1} spacing={1}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant={'body2'} className={'medium'}>
            Monthly premium:
          </Typography>
          <Typography variant={'body2'}>
            <b>{plan.monthlyPremium}</b>
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant={'body2'} className={'medium'}>
            Est. drug costs:
          </Typography>
          <Typography variant={'body2'}>
            <b>{plan.drugsCost || 'N/A'}</b>
          </Typography>
        </Stack>
      </Stack>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    maxWidth: 248,
    padding: 16,
  },
  padding: '16px 24px 16px 16px',
  background: 'rgba(28,67,79,0.06)',
  width: '100%',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
}));
