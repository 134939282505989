import { Button, Dialog } from '@mui/material';
import React, { useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getIsAdmin, keycloak } from '@coverright/shared/keycloak';
import { DrugPriceOutput, IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { ModalCloseButton } from '@coverright/shared/ui';
import { PDFViewer } from '@react-pdf/renderer';
import { DrugCostBreakdownPdfTemplate } from '../pdf/drug-cost-breakdown-pdf-template';
import { RegulatoryVariable } from '@coverright/data-access/types/medicare';

interface Props {
  plan: IDrugCostBreakdownPlan;
  drugPrices: DrugPriceOutput[];
  regulatory?: RegulatoryVariable;
}

export function DrugCostBreakdownPdfButton({plan, drugPrices, regulatory}: Props) {
  const [modalOpen, setModalOpen] = useState(false);

  if (!getIsAdmin(keycloak)) {
    return null;
  }

  return (<>
    <Dialog open={modalOpen} fullScreen>
      <ModalCloseButton onClick={() => setModalOpen(false)} sx={{alignSelf: 'flex-end'}} />
      <PDFViewer style={{height: '-webkit-fill-available'}}>
        <DrugCostBreakdownPdfTemplate plan={plan} drugPrices={drugPrices} regulatory={regulatory} />
      </PDFViewer>
    </Dialog>
    <Button variant={'outlined'} onClick={() => setModalOpen(true)} startIcon={<FileDownloadIcon />}>
      .pdf
    </Button>
  </>);
}
