import * as React from 'react';
import { Stack, Tooltip, Typography } from '@mui/material';
import { Info } from '@phosphor-icons/react';

export const RowTitle = ({
  title,
  tooltip = '',
}: {
  tooltip?: string;
  title: string | React.JSX.Element;
}) => (
  <Stack
    alignItems={'flex-start'}
    sx={{ flexDirection: { xs: 'column', md: 'row' }, mt: 0.5, gap: 0.5 }}
  >
    {tooltip && (
      <Info
        style={{ minWidth: 14, marginTop: 2 }}
        size={14}
        color={'#216069'}
        weight={'fill'}
      />
    )}
    <Tooltip arrow title={tooltip} placement={'right'}>
      {typeof title === 'string' ? (
        <Typography variant={'caption'}>
          <b>{title}</b>
        </Typography>
      ) : (
        title
      )}
    </Tooltip>
  </Stack>
);
