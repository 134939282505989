// @flow
import * as React from 'react';
import { useIsMAState, useIsWIState } from '@coverright/data-access/medicare';
import { Box, Collapse, styled, SxProps, Typography } from '@mui/material';
import { colors } from '@coverright/ui/themes';
import { getDeductible } from '@coverright/utils';
import { CaretDown } from '@phosphor-icons/react';
import { PlanCategory } from '../mg-filters/lib'; // todo remove dependency
import { MedigapQuoteQuery } from '@coverright/data-access/types/medigap';

export function PlanCategoryDetails({quote, planCategory, collapsedByDefault = false }: {
  planCategory?: string | null;
  quote: MedigapQuoteQuery['medigapQuote'];
  collapsedByDefault?: boolean
}) {
  const isMassachusetts = useIsMAState(quote?.medigapFilterState.zip);
  const isWisconsin = useIsWIState(quote?.medigapFilterState.zip);
  const [collapsed, setCollapsed] = React.useState(collapsedByDefault);

  if (isMassachusetts || isWisconsin || !quote || planCategory === 'all') {
    return null;
  }

  return (
    <Wrapper>
      <Collapse collapsedSize={23} in={!collapsed}>
        {getLabelForCategory({
          cat: planCategory as PlanCategory,
          sx: {
            '& ul': {
              marginBlockStart: 0,
              marginBlockEnd: 0,
              paddingInlineStart: 0,
              listStyle: 'inside',
              '& li': {
                color: colors.text.primary,
                lineHeight: '20px',
                fontSize: 14,
              },
            },
          },
          skipTitle: false,
          titleClassName: 'fs-14 bold mb-10',
          age: quote.medigapFilterState.age,
        })}
      </Collapse>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: 15,
          right: 26,
          cursor: 'pointer',
        }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Typography className={'fs-14 lh-14'}>Details</Typography>
        <Box
          component={'span'}
          sx={{
            ml: '5px',
            transition: 'all 0.3s',
            transform: `rotate(${collapsed ? '-180' : '0'}deg)`,
          }}
        >
          <CaretDown size={14} color={'#1c434f'} />
        </Box>
      </Box>
    </Wrapper>
  );
}

const Wrapper = styled(Box)({
  background: 'white',
  borderRadius: '0px 0px 8px 8px',
  padding: '11px 20px',
  position: 'relative',
});

type LabelForCategoryProps = {
  cat: PlanCategory;
  sx?: SxProps;
  skipTitle?: boolean;
  titleClassName?: string;
  showShort?: boolean;
  age?: number;
};

export const getLabelForCategory = (props: LabelForCategoryProps) => {
  const { cat, sx, skipTitle, titleClassName, showShort, age } = props;
  switch (cat) {
    case PlanCategory.MostPopular:
      return (
        <>
          {!skipTitle && (
            <Typography className={titleClassName || 'fs-12 bold'}>
              Plan F, G and N
            </Typography>
          )}
          {showShort && (
            <Typography className={'fs-14'} sx={{ letterSpacing: '-1px' }}>
              Selected by over <strong className={'dark-green'}>85%</strong> of
              all Medicare Supplement enrollees.
            </Typography>
          )}
          <Box sx={sx}>
            <ul>
              <li>
                Selected by <strong className={'dark-green'}>85%</strong> of all
                Medicare Supplement enrollees. These plans also represent the
                plans we recommend most to customers purchasing Medicare
                Supplement
              </li>
              <li>
                You pay a higher monthly premium in exchange for minimal
                out-of-pocket costs
              </li>
              <li>
                Most beneficiaries will select a plan in this category based on
                monthly premium budget
              </li>
              <li>
                Plan G is now most popular plan for those new to Medicare as
                Plan F is no longer available to those who became newly eligible
                for Medicare after Jan 1, 2020
              </li>
            </ul>
          </Box>
        </>
      );
    case PlanCategory.CoreBenefits:
      return (
        <>
          {!skipTitle && (
            <Typography
              color={'textPrimary'}
              className={titleClassName || 'fs-12 bold'}
            >
              Plan A and B
            </Typography>
          )}
          {showShort && (
            <Typography className={'fs-14'} sx={{ letterSpacing: '-1px' }}>
              Selected by <strong className={'dark-green'}>2%</strong> of all
              Medicare Supplement enrollees.
            </Typography>
          )}
          <Box sx={sx}>
            <ul>
              <li>
                Selected by <strong className={'dark-green'}>2%</strong> of all
                Medicare Supplement enrollees. In general, we recommend
                consumers looking at Plan A/B to review Medicare Advantage
                options instead
              </li>
              <li>
                These plans include basic benefits such as coverage for your 20%
                out-of-pocket for your medical services (also known as the Part
                B coinsurance)
              </li>
              <li>
                Plan B includes coverage for the short-term hospital stay
                deductible of {getDeductible('inpatientHospitalDeductible')}{' '}
                which covers first 60 days of stay (also known as the Part A
                deductible)
              </li>
              <li>
                You may sometimes find that a plan with less coverage may cost
                similar or more than a plan with more coverage. This can
                sometimes occur because the plan with less coverage is less
                popular and carriers may need to charge more to cover the costs
                of paying claims (while more popular plans can average out claim
                costs across a larger population).
              </li>
            </ul>
          </Box>
        </>
      );
    case PlanCategory.CostShare: return <>
      {!skipTitle && <Typography color={'textPrimary'} className={titleClassName || 'fs-12 bold'}>Plan K, L and M</Typography>}
      {showShort && <Typography className={'fs-14'} sx={{letterSpacing: '-1px'}}>Selected by <b className={'dark-green'}>1%</b> of all Medicare Supplement enrollees.</Typography>}
      <Box sx={sx}>
        <ul>
          <li>Selected by <b className={'dark-green'}>1%</b> of all Medicare Supplement enrollees.</li>
          <li>For Plans K, L and M, you pay 25-50% of out-of-pocket obligations. Once an annual out-of-pocket limit is reached, the plan pays 100% of costs</li>
          <li><b>Note:</b> You may sometimes find that a plan with less coverage may cost similar or more than a plan with more coverage. This can sometimes occur because the plan with less coverage is less popular and carriers may need to charge more to cover the costs of paying claims (while more popular plans can average out claim costs across a larger population).</li>
        </ul>
      </Box>
    </>;
    case PlanCategory.HighDeductible:
      return (
        <>
          {!skipTitle && (
            <Typography
              color={'textPrimary'}
              className={titleClassName || 'fs-12 bold'}
            >
              Plan F and G (high deductible)
            </Typography>
          )}
          {showShort && (
            <Typography className={'fs-14'} sx={{ letterSpacing: '-1px' }}>
              High deductible versions of the two most popular plans (Plan F, G)
            </Typography>
          )}
          <Box sx={sx}>
            <ul>
              <li>
                High deductible versions of the two most popular plans (Plan F
                and G)
              </li>
              <li>
                You pay a deductible of {getDeductible('deductible')} before the
                plan begins to pay
              </li>
              <li>
                Once the deductible is met, the plan covers 100% of the costs
                like a regular Plan F and G
              </li>
              <li>
                These plans are a good option if you want network flexibility as
                well as catastrophic coverage and do not expect to use
                healthcare services often
              </li>
              <li>
                Plan F is no longer available to those who became newly eligible
                for Medicare after Jan 1, 2020
              </li>
            </ul>
          </Box>
        </>
      );
    default:
      return <></>;
  }
};
