// @flow
import * as React from 'react';
import { DrugPriceOutput } from '@coverright/data-access/medicare';
import { TitleRow } from '../../ma-plan-details/pdf/lib';
import { Text } from '@react-pdf/renderer';
import { PdfBenefit, styles } from '@coverright/shared/pdf';
import { Row, TableCell, TableHeader } from './lib';
import * as _ from 'lodash';

type Props = {
  drugPrices: DrugPriceOutput[];
};

export function DrugCostBreakdownPdfTemplateCoveragePhases({drugPrices}: Props) {

  return (
    <>
      <TitleRow title={'By coverage phases'} />

      <Row>
        <TableHeader>Drug</TableHeader>
        <TableHeader>Tier</TableHeader>
        <TableHeader>Deductible</TableHeader>
        <TableHeader>Initial coverage</TableHeader>
        <TableHeader>Catastrophic coverage</TableHeader>
      </Row>

      {drugPrices.map(price => (
        <Row key={price.packageDescription}>
          <TableCell>
            <Text style={[styles.text, {fontWeight: 'semibold'}]}>
              {price.packageDescription} {price.isAggregatedPrice ? '*' : ''}
            </Text>
          </TableCell>
          <TableCell>
            <Text style={styles.text}>
              {!price.tier
                ? 'Not covered'
                : 'Tier ' + _.toString(price.tier)?.replace('Tier', '')}
            </Text>
          </TableCell>
          <TableCell>
            <Text style={styles.text}>
              {price.price} {price.isAggregatedPrice ? '*' : ''}
            </Text>
          </TableCell>
          <TableCell>
            <Text style={styles.text}>
              {price.initialCoveragePrice}
            </Text>
          </TableCell>
          <TableCell>
            <Text style={styles.text}>
              {price.catastrophicPrice}
            </Text>
          </TableCell>
        </Row>
      ))}
    </>
  );
}
