import { getApolloClient } from './ApolloClient';
import { getToken } from '@coverright/shared/keycloak';
import { InMemoryCacheConfig } from '@apollo/client';
import moment from 'moment/moment';
import { getAgeByBirthdate } from '@coverright/utils';

const inMemoryCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    UserProfileOutput: {
      keyFields: ['profileId'],
      fields: {
        birthDate: (existing) => {
          if (existing && /\d{4}-\d{2}-\d{2}/gm.test(existing)) {
            return moment(existing, 'YYYY-MM-DD').format('MM/DD/YYYY');
          }
          return existing;
        },
        age: (existing, { readField }) => {
          return getAgeByBirthdate(readField('birthDate'), 'MM/DD/YYYY');
        },
        fullName: (existing, { readField }) => {
          return [readField('firstName'), readField('lastName')].join(' ');
        },
      },
      queryType: true,
    },
    AgentViewOutput: {
      fields: {
        picUrl: (existing, options) => {
          return existing ? process.env.NX_CDN_URL + existing : undefined;
        },
      },
    },
  },
};

const { client: enrollmentApolloClient, cache: enrollmentApolloCache, preloadQuery: enrollmentPreloadQuery } = getApolloClient(
  getToken,
  process.env.NX_ENROLLMENT_GRAPHQL,
  inMemoryCacheConfig
);
export { enrollmentApolloClient, enrollmentApolloCache, enrollmentPreloadQuery };

