import { PlanYear } from '@coverright/data-access/types/medicare';
import { Path, Svg, View, Text } from '@react-pdf/renderer';
import * as React from 'react';
import { GetDefaultPlansYear } from '@coverright/utils';
import { styles } from './styles';

interface StarRatingProps {
  plan?: { rating?: number | null, ratingNote?: string | null, planYear?: PlanYear | null; },
}

export const PdfStarRating = ({ plan }: StarRatingProps) => {
  if (!plan || (!plan.rating && !plan.ratingNote)) {
    return null;
  }

  const planYear = (plan?.planYear || GetDefaultPlansYear())
    .toString()
    .replace('Year', '');

  let rating = plan.rating || 0;

  const mapArr = Array(5).fill(null).map((_, i) => {
    const r = rating;
    rating > 1 ? rating-- : rating = 0;
    return r >= 1 ? 1 : r
  })

  return !plan.rating ? (
    <Text style={[styles.text, { fontSize: 12, lineHeight: '18px', marginTop: 5 }]}>{plan.ratingNote}</Text>
  ) : (
    <View style={{ display: 'flex', flexDirection: 'row', gap: 6, alignItems: 'center' }}>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        {mapArr.map((v, i) => (
          v === 1 ? <Star key={i} /> : v === .5 ? <StarHalf key={i} /> : <StarBlank key={i} />
        ))}
      </View>
      <Text style={[styles.text, { fontSize: 10, lineHeight: '18px', marginTop: 5 }]}>({planYear})</Text>
    </View>
  )
}

const Star = () => (
  <Svg width={18} height={18} fill={'#1e92a0'} viewBox={'0 0 256 256'}>
    <Path
      d={'M234.29,114.85l-45,38.83L203,211.75a16.4,16.4,0,0,1-24.5,17.82L128,198.49,77.47,229.57A16.4,16.4,0,0,1,53,211.75l13.76-58.07-45-38.83A16.46,16.46,0,0,1,31.08,86l59-4.76,22.76-55.08a16.36,16.36,0,0,1,30.27,0l22.75,55.08,59,4.76a16.46,16.46,0,0,1,9.37,28.86Z'} />
  </Svg>
)

const StarBlank = () => (
  <Svg width={18} height={18} fill={'#1e92a0'} viewBox={'0 0 256 256'}>
    <Path opacity={.2}
          d={'M229.06,108.79l-48.7,42,14.88,62.79a8.4,8.4,0,0,1-12.52,9.17L128,189.09,73.28,222.74a8.4,8.4,0,0,1-12.52-9.17l14.88-62.79-48.7-42A8.46,8.46,0,0,1,31.73,94L95.64,88.8l24.62-59.6a8.36,8.36,0,0,1,15.48,0l24.62,59.6L224.27,94A8.46,8.46,0,0,1,229.06,108.79Z'} />
    <Path
      d={'M239.18,97.26A16.38,16.38,0,0,0,224.92,86l-59-4.76L143.14,26.15a16.36,16.36,0,0,0-30.27,0L90.11,81.23,31.08,86a16.46,16.46,0,0,0-9.37,28.86l45,38.83L53,211.75a16.38,16.38,0,0,0,24.5,17.82L128,198.49l50.53,31.08A16.4,16.4,0,0,0,203,211.75l-13.76-58.07,45-38.83A16.43,16.43,0,0,0,239.18,97.26Zm-15.34,5.47-48.7,42a8,8,0,0,0-2.56,7.91l14.88,62.8a.37.37,0,0,1-.17.48c-.18.14-.23.11-.38,0l-54.72-33.65a8,8,0,0,0-8.38,0L69.09,215.94c-.15.09-.19.12-.38,0a.37.37,0,0,1-.17-.48l14.88-62.8a8,8,0,0,0-2.56-7.91l-48.7-42c-.12-.1-.23-.19-.13-.5s.18-.27.33-.29l63.92-5.16A8,8,0,0,0,103,91.86l24.62-59.61c.08-.17.11-.25.35-.25s.27.08.35.25L153,91.86a8,8,0,0,0,6.75,4.92l63.92,5.16c.15,0,.24,0,.33.29S224,102.63,223.84,102.73Z'} />
  </Svg>
)

const StarHalf = () => (
  <Svg width={18} height={18} fill={'#1e92a0'} viewBox={'0 0 256 256'}>
    <Path opacity={.2}
          d={'M229.06,108.79l-48.7,42,14.88,62.79a8.4,8.4,0,0,1-12.52,9.17L128,189.09,73.28,222.74a8.4,8.4,0,0,1-12.52-9.17l14.88-62.79-48.7-42A8.46,8.46,0,0,1,31.73,94L95.64,88.8l24.62-59.6a8.36,8.36,0,0,1,15.48,0l24.62,59.6L224.27,94A8.46,8.46,0,0,1,229.06,108.79Z'} />
    <Path
      d={"M239.18,97.26A16.38,16.38,0,0,0,224.92,86l-59-4.76L143.14,26.15a16.36,16.36,0,0,0-30.27,0L90.11,81.23,31.08,86a16.46,16.46,0,0,0-9.37,28.86l45,38.83L53,211.75a16.4,16.4,0,0,0,24.5,17.82L128,198.49l50.53,31.08A16.4,16.4,0,0,0,203,211.75l-13.76-58.07,45-38.83A16.43,16.43,0,0,0,239.18,97.26Zm-15.34,5.47-48.7,42a8,8,0,0,0-2.56,7.91l14.88,62.8a.37.37,0,0,1-.17.48c-.18.14-.23.11-.38,0l-54.72-33.65A8,8,0,0,0,128,181.1V32c.24,0,.27.08.35.26L153,91.86a8,8,0,0,0,6.75,4.92l63.91,5.16c.16,0,.25,0,.34.29S224,102.63,223.84,102.73Z"} />
  </Svg>
)

