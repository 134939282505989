// @flow
import * as React from 'react';
import { MaPlan } from '../../ma-plan-list/api/use-ma-plans';
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { PropsWithChildren } from 'react';
import { PdfStarRating, styles } from '@coverright/shared/pdf';
import { getCompanyLogo } from '@coverright/utils';

type Props = {
  plan: MaPlan
};

export function MaPlanDetailsPdfTemplateHeader({ plan }: Props) {
  return (
    <Wrapper>
      <View style={{flex: 1}}>
        <View style={[localStyles.containerSection, {marginBottom: 16}]}>
          <PdfStarRating plan={plan} />
          <View style={{height: 30}}>
            {getCompanyLogo(plan.parentOrgName, plan.orgName) && (
              <Image src={getCompanyLogo(plan.parentOrgName, plan.orgName).replace('.svg', '.png')} style={{objectFit: 'contain', objectPosition: 'right'}} />
            )}
          </View>
        </View>
        <Text style={localStyles.planName}>{plan.planName}</Text>
      </View>

      <Prices>
        <Text style={[styles.text, {fontSize: 25, lineHeight: 1.2, fontWeight: 'bold', alignSelf: 'center'}]}>{plan.monthlyCost || 'N/A'}</Text>
        <Text style={[styles.text, {fontSize: 9, fontWeight: 'semibold', alignSelf: 'center'}]}>Est. Avg. total cost /mo</Text>
        <View style={localStyles.containerSection}>
          <Text style={[styles.text, {fontWeight: 'medium'}]}>Monthly premium:</Text>
          <Text style={[styles.text, {fontWeight: 'semibold'}]}>{plan.monthlyPremium}</Text>
        </View>
        <View style={[localStyles.containerSection]}>
          <Text style={[styles.text, {fontWeight: 'medium'}]}>Est. drug costs:</Text>
          <Text style={[styles.text, {fontWeight: 'semibold'}]}>{plan.drugsCost || 'N/A'}</Text>
        </View>
      </Prices>
    </Wrapper>
  );
}

const Wrapper = (props: PropsWithChildren) => (
  <View style={localStyles.wrapper}>{props.children}</View>
)

const Prices = (props: PropsWithChildren) => (
  <View style={localStyles.prices}>{props.children}</View>
)

const localStyles = StyleSheet.create({
  wrapper: {
    border: '1px solid #E5E7EB',
    borderRadius: '16px',
    padding: 8,
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
  },
  containerSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    justifyContent: 'space-between',
  },
  planName: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'medium',
    fontFamily: 'Epilogue',
    textAlign: 'left'
  },
  prices: {
    backgroundColor: 'rgba(28,67,79,0.06)',
    borderRadius: '16px',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  }
})


