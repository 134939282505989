// @flow
import * as React from 'react';
import { styled, Typography } from '@mui/material';
import { PdpPlanListQuery } from '@coverright/data-access/medicare';
import { GetDefaultPlansYear, tooltips, useDeviceWidth } from '@coverright/utils';
import { PdpSavePlanButton } from './pdp-save-plan-button';
import { Tooltiped } from '@coverright/features';
import { PdpDrugCostBreakdownButton } from '../../..';
import { useQuoteId } from '@coverright/shared/contexts';

type Props = {
  plan: PdpPlanListQuery['PdpPlans']['data'][0];
};

export const PdpPlanListItemPrices = ({ plan }: Props) => {
  const showDrugCostButton = !!plan.drugsCoverage.length;
  const phone = useDeviceWidth('md');
  const quoteId = useQuoteId();

  return (
    <Wrapper>
      <Typography sx={{ fontSize: 28, lineHeight: 1, fontWeight: 700 }}>
        {plan.monthlyCost || 'N/A'}
      </Typography>

      <Tooltiped
        tooltip={tooltips.pdpTotalCost}
        iconProps={{ style: phone ? undefined : { right: -10 } }}
      >
        <Typography variant={'body2'} sx={{ mt: 1 }}>
          <b>estimated average total cost per month</b>
        </Typography>
      </Tooltiped>

      {showDrugCostButton && (
        <PdpDrugCostBreakdownButton
          sx={{ whiteSpace: 'nowrap' }}
          variant={'outlined'}
          size={phone ? 'large' : 'small'}
          bidId={plan.bidId}
          quoteId={quoteId}
          planYear={plan.planYear || GetDefaultPlansYear()}
          id={'pdp-plan-drug-cost-breakdown-button'}
        >
          Drug Cost Breakdown
        </PdpDrugCostBreakdownButton>
      )}
      <PdpSavePlanButton size={phone ? 'large' : 'small'} plan={plan} />
    </Wrapper>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 248,
  },
  padding: '24px',
  background: 'rgba(28,67,79,0.06)',
  width: '100%',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
}));
