import React from 'react';
import { Box, Rating, Typography } from '@mui/material';
import { Info, Star } from '@phosphor-icons/react';
import { GetDefaultPlansYear, tooltips } from '@coverright/utils';
import { Tooltip } from '@coverright/ui/inputs';
import { PlanYear } from '@coverright/data-access/types/medicare';

interface StarRatingProps {
  plan?: { rating?: number | null, ratingNote?: string | null, planYear?: PlanYear | null; },
  size?: number,
  endAdornment?: JSX.Element,
  labelClassName?: string
}

export function StarRating({plan, size = 18, endAdornment, labelClassName = 'fs-12'}: StarRatingProps) {
  if (!plan || (!plan.rating && !plan.ratingNote)) {
    return null;
  }
  const planYear = (plan?.planYear || GetDefaultPlansYear())
    .toString()
    .replace('Year', '');

  if (!endAdornment) {
    endAdornment = <Box sx={{ display: 'flex', gap: 0.5 }}>
      {!!plan?.rating && (
        <Typography sx={{ ml: 1, mt: 0.25, lineHeight: 1, fontSize: 14 }}>
          ({planYear})
        </Typography>
      )}
      <Info size={16} color={'#7b8f95'} weight={'bold'} />
    </Box>
  }

  const rating = !plan.rating ? <Typography sx={{color: '#4D4D4D'}} className={`no-wrap medium ${labelClassName || ''}`} >{plan.ratingNote}</Typography>
    : <Rating emptyIcon={<Star size={size} style={{minWidth: size}} color="#1e92a0" weight="duotone" />}
              icon={<Star size={size} style={{minWidth: size}} color="#1e92a0" weight="fill" />}
              defaultValue={plan.rating || 0}
              precision={.5}
              readOnly
    />;

  return <Tooltip arrow title={tooltips.starRating}>
    <Box sx={{width: 'fit-content'}} className={'flex flex-align-center'}>
      {rating}
      {endAdornment}
    </Box>
  </Tooltip>;
}
