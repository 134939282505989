import { ResponsiveTable, ResponsiveTableColumn } from '@coverright/features';
import { Box, Typography } from '@mui/material';
import { Tooltip } from '@coverright/ui/inputs';
import { DrugPriceOutput, PlanYear } from '@coverright/data-access/types/medicare';
import * as _ from 'lodash';
import * as React from 'react';
import { DrugCostBreakdownVariant } from '../../../lib/types';

export const CoveragePhaseTable = ({
  drugPrices, variant, planYear
}: {
  drugPrices: DrugPriceOutput[];
  variant?: DrugCostBreakdownVariant;
  planYear?: PlanYear;
}) => {
  return (
    <div>
      {!['z1', 'v3'].includes(variant as string) && <HelperRow is2024={planYear === PlanYear.Year2024} />}
      <ResponsiveTable columns={coveragePhaseColumns}
                       rows={drugPrices} />
    </div>
  );
};

const HelperRow = ({ is2024 }: {is2024: boolean}) => {
  return (
    <Box
      component={'table'}
      sx={{
        tableLayout: 'fixed',
        width: '100%',
        background: 'rgba(164, 180, 185, 0.1)',
        fontSize: 12,
        '& td': {
          verticalAlign: 'top',
          padding: '9px 0 9px 15px',
        },
      }}
    >
      <tbody>
        <tr>
          <td>What you pay</td>
          <td></td>
          <td>
            <Tooltip
              placement={'top'}
              arrow
              title={
                "This is an estimate of the regular cash retail price you would pay at your selected pharmacy. You pay this price until you meet your plan's deductible (if there is one). This price is also used to determine the coinsurance in the Initial Coverage phase (if applicable), Coverage Gap phase and Catastrophic Coverage phase."
              }
            >
              <Typography
                color={'textPrimary'}
                className={'pointer underline fs-12'}
              >
                Estimated full retail price
              </Typography>
            </Tooltip>
          </td>
          <td>
            <Tooltip
              placement={'top'}
              arrow
              title={
                "This is the copay or coinsurance that you will pay under this plan's Initial Coverage phase. This amount is dependent on the quantity and days' supply you purchase, the drug tier, and also whether you purchase from a preferred or standard retail pharmacy or mail order pharmacy. If the retail price is less than the copay or coinsurance, you pay the retail price instead."
              }
            >
              <Typography
                color={'textPrimary'}
                className={'pointer underline fs-12'}
              >
                Minimum of retail price and plan copay/ coinsurance (based on
                tier)
              </Typography>
            </Tooltip>
          </td>
          {is2024 && <td>
            <Tooltip
              placement={'top'}
              arrow
              title={
                'This is the amount you pay in this plan\'s Coverage Gap phase. Typically, this price is 25% of the retail price, for both generic and branded drugs. However, some plans have lower copays or coinsurance in this phase for some drug tiers.'
              }
            >
              <Typography
                color={'textPrimary'}
                className={'pointer underline fs-12'}
              >
                Typically 25% of retail (both branded and generic)
              </Typography>
            </Tooltip>
          </td>}
          <td>
            <Tooltip
              placement={'top'}
              arrow
              title={
                "This is the amount you pay in this plan's Catastrophic Coverage phase. The price in this phase is always $0 for generic drugs, and $0 for branded drugs."
              }
            >
              <Typography
                color={'textPrimary'}
                className={'pointer underline fs-12'}
              >
                You'll pay $0 (generics) / $0 (branded)
              </Typography>
            </Tooltip>
          </td>
        </tr>
      </tbody>
    </Box>
  );
};

const coveragePhaseColumns: ResponsiveTableColumn<DrugPriceOutput>[] = [
  {
    title: 'Drug',
    value: (drug) => (
      <>
        {drug.packageDescription}
        {drug.isAggregatedPrice ? <sup>*</sup> : ''}
      </>
    ),
  },
  {
    title: 'Tier',
    value: (drug) => (
      drug.error ? <Typography sx={{ color: '#B3B3B3' }}>&#8212;</Typography> :
      <>
        {!drug.tier
          ? 'Not covered'
          : 'Tier ' + _.toString(drug.tier)?.replace('Tier', '')}
      </>
    ),
  },
  {
    title: 'Deductible',
    value: (drug) => (
      drug.error ? <Typography sx={{ color: '#B3B3B3' }}>&#8212;</Typography> :
      <div>
        {drug.price} {drug.isAggregatedPrice ? <sup>*</sup> : ''}
      </div>
    ),
  },
  {
    title: 'Initial coverage',
    value: (drug) =>
      drug.error ? <Typography sx={{ color: '#B3B3B3' }}>&#8212;</Typography> : drug.initialCoveragePrice,
  },
  {
    title: 'Catastrophic coverage',
    value: (drug) =>
      drug.error ? <Typography sx={{ color: '#B3B3B3' }}>&#8212;</Typography> :drug.catastrophicPrice,
  },
];
