// @flow
import * as React from 'react';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { PdfStarRating } from '@coverright/shared/pdf';
import { getCompanyLogo } from '@coverright/utils';

type Props = {
  plan: IDrugCostBreakdownPlan;
};

export function DrugCostBreakdownPdfTemplateHeader({ plan }: Props) {
  return (
    <View style={localStyles.wrapper}>
      <View>
        <Text style={localStyles.planName}>{plan.planName}</Text>
        <PdfStarRating plan={plan} />
      </View>

      <View style={{height: 30}}>
        {getCompanyLogo(plan.parentOrgName, plan.orgName) && (
          <Image src={getCompanyLogo(plan.parentOrgName, plan.orgName).replace('.svg', '.png')} style={{objectFit: 'contain', objectPosition: 'right'}} />
        )}
      </View>
    </View>
  );
}

const localStyles = StyleSheet.create({
  wrapper: {
    border: '1px solid #E5E7EB',
    borderRadius: '16px',
    padding: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
  },
  planName: {
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'medium',
    fontFamily: 'Epilogue',
    textAlign: 'left'
  }
})
