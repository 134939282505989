// @flow
import * as React from 'react';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { TitleRow } from '../../ma-plan-details/pdf/lib';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '@coverright/shared/pdf';
import { getCatastrophicCoverageExplanation, getInitialCoverageExplanation } from '../lib/lib';

type Props = {
  plan: IDrugCostBreakdownPlan;
};

export function DrugCostBreakdownPdfTemplateYearlyEstimationCoverage({plan}: Props) {
  const initialCoverage = getInitialCoverageExplanation(plan);
  const catastrophicCoverage = getCatastrophicCoverageExplanation(plan);

  return (
    <>
      <TitleRow title={'Yearly estimation coverage'} />

      <Section {...initialCoverage} color={'#EFEBE1'} title={'Initial Coverage'} />
      <Section {...catastrophicCoverage} color={'#FCF2C9'} title={'Catastrophic Coverage'} />
    </>
  );
}

const Section = ({label, content, color, title}: {label: string, content: string, color: string, title: string}) => (
  <View style={{ borderLeft: `8px solid ${color}`, paddingLeft: 10, paddingVertical: 8, paddingRight: 8 }}>
    <Text style={titleStyle}>{title}</Text>
    <Text style={[styles.text, {fontWeight: 'semibold'}]}>{label}</Text>
    <Text style={styles.text}>{content}</Text>
  </View>
)

const titleStyle: any = {
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 'semibold',
  fontFamily: 'Epilogue',
  textAlign: 'left',
}
