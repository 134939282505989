// @flow
import * as React from 'react';
import { Card, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMaPlanFilter } from './api/use-ma-plan-filter';
import { usePartDConfirm } from './ui/part-d-confirm';
import { useDsnpOffConfirm } from './ui/dsnp-off-confirm';
import { useDsnpOnConfirm } from './ui/dsnp-on-confirm';
import { MedicareQuoteQuery } from '@coverright/data-access/medicare';
import { getNumbersFromString, useDeviceWidth } from '@coverright/utils';
import { FilterCheckbox } from '@coverright/features';
import { useMaPlansInfoQuery } from '@coverright/data-access/types/medicare';

type MaFiltersProps = {
  quote: MedicareQuoteQuery['medicareQuote'],
  onChange: (values: Form) => void;
};

export const MaFilters = ({ quote, onChange }: MaFiltersProps) => {
  const phone = useDeviceWidth('sm');
  const filterValues = quote?.filters;
  const {data: info} = useMaPlansInfoQuery()
  const doctorsCheckBoxMessage = info?.maPlansInfo?.doctorsCheckBoxMessage;
  const drugsCheckBoxMessage = info?.maPlansInfo?.drugsCheckBoxMessage;

  const formik = useFormik<Form>({
    initialValues: {
      planTypes: filterValues?.planTypes || [],
      SNPTypes: filterValues?.SNPTypes || [],
      extraBenefits: filterValues?.extraBenefits || [],
      companies: filterValues?.companies || [],
      showTopDoctors: filterValues?.showTopDoctors || false,
      showTopDrugs: filterValues?.showTopDrugs || false,
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: () => {},
  });

  const filters = useMaPlanFilter(quote, formik.values as any);

  const partDConfirm = usePartDConfirm();
  const dsnpOffConfirm = useDsnpOffConfirm();
  const dsnpOnConfirm = useDsnpOnConfirm();

  const toggleValue = async (
    fieldName: keyof Form,
    name: string,
    value: boolean
  ) => {
    if (fieldName === 'extraBenefits' && value === false) {
      const result = await partDConfirm();
      if (!result) {
        return;
      }
    }

    if (fieldName === 'SNPTypes') {
      const result = value ? await dsnpOnConfirm() : await dsnpOffConfirm();
      if (!result) {
        return;
      }
    }

    let newValue: any = value;

    if (Array.isArray(formik.values[fieldName])) {
      const arrayWithoutValue = _.without(
        formik.values[fieldName] as string[],
        name
      );
      newValue = value ? [...arrayWithoutValue, name] : arrayWithoutValue;
    }

    const newValues = { ...formik.values, [fieldName]: newValue };

    if (!doctorsCheckBoxMessage && newValues.showTopDoctors) {
      newValues['showTopDoctors'] = false;
    }

    if (!drugsCheckBoxMessage && newValues.showTopDrugs) {
      newValues['showTopDrugs'] = false;
    }

    formik.setFieldValue(fieldName, newValue);
    onChange(newValues);
  };

  return (
    <Card sx={{ minWidth: 260, p: { xs: 0, sm: 3 } }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          {!phone && (
            <Typography variant={'h3'}>Filter your results</Typography>
          )}

          {doctorsCheckBoxMessage && (
            <FilterCheckbox
              name={'showTopDoctors'}
              onChange={(value) => {
                toggleValue('showTopDoctors', '', value)
              }}
              checked={formik.values.showTopDoctors}
              singleLine={false}
              label={'View plans that cover all of your doctors'}
              count={getNumbersFromString(doctorsCheckBoxMessage)[0]}
            />
          )}

          {drugsCheckBoxMessage && (
            <FilterCheckbox
              name={'showTopDrugs'}
              onChange={(value) => {
                toggleValue('showTopDrugs', '', value)
              }}
              checked={formik.values.showTopDrugs}
              singleLine={false}
              label={'View plans that cover all of your drugs'}
              count={getNumbersFromString(drugsCheckBoxMessage)[0]}
            />
          )}

          {Object.keys(filters).map((filterName, index) => (
            <div key={filterName}>
              <Typography sx={{ fontWeight: 500, mb: 1 }}>
                {titles[index]}
              </Typography>
              {filters[filterName as keyof typeof filters].map(
                (item, index) => (
                  <FilterCheckbox
                    key={item.value}
                    name={filterName}
                    disabled={
                      item.disabled &&
                      !formik.values[
                        filterName as keyof typeof filters
                      ].includes(item.value)
                    }
                    onChange={(value) =>
                      toggleValue(
                        filterName as keyof typeof filters,
                        item.value,
                        value
                      )
                    }
                    checked={formik.values[
                      filterName as keyof typeof filters
                    ].includes(item.value)}
                    label={item.label}
                    count={item.count}
                  />
                )
              )}
            </div>
          ))}
        </Stack>
      </form>
    </Card>
  );
};

interface Form {
  planTypes: string[];
  SNPTypes: string[];
  extraBenefits: string[];
  companies: string[];
  showTopDoctors: boolean;
  showTopDrugs: boolean;
}

const schemaFields = {
  planTypes: yup.array(),
  SNPTypes: yup.array(),
  extraBenefits: yup.array(),
  companies: yup.array(),
};

const titles = ['Plan type', 'Special Needs', 'Drug Coverage', 'Insurance'];
