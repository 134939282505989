import { createTheme } from '@mui/material';
import React from 'react';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/epilogue/300.css';
import '@fontsource/epilogue/400.css';
import '@fontsource/epilogue/500.css';
import '@fontsource/epilogue/600.css';
import '@fontsource/epilogue/700.css';

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['text'];
    white?: Palette['primary'];
  }

  interface PaletteOptions {
    accent?: PaletteOptions['text'];
    white?: PaletteOptions['primary'];
  }

  interface TypographyVariants {
    caption: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    caption?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    caption: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    white: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    outlined: true;
    white: true;
  }
}

declare module '@mui/material/TextField' {
  interface TextFieldPropsColorOverrides {
    white: true;
  }
}

export const z1Theme = createTheme({
  palette: {
    white: {
      main: '#FFF',
      light: '#CBE4EC',
    },
    primary: {
      '50': '#E0F0F5',
      '100': '#CBE4EC',
      '200': '#9DCEDC',
      '300': '#68B4C8',
      '400': '#4599B2',
      '500': '#337F96',
      '600': '#2A677A',
      '700': '#275766',
      '800': '#1C434F',
      '900': '#0F2F38',
      main: '#1C434F',
      light: '#2A677A',
    },
    secondary: {
      '50': '#D5F8F7',
      '100': '#B0F1F1',
      '200': '#79E6E7',
      '300': '#3CD1D4',
      '400': '#20B5BA',
      '500': '#1E95A0',
      '600': '#1E7680',
      '700': '#216069',
      '800': '#1E4A53',
      '900': '#12343B',
      main: '#3CD1D4',
      dark: '#20B5BA',
      light: '#79E6E7',
    },
    warning: {
      '50': '#FCF2C9',
      '100': '#F9E69A',
      '200': '#F5CF54',
      '300': '#F2BB2D',
      '400': '#EB9C15',
      '500': '#D0760F',
      '600': '#AD5410',
      '700': '#8D4113',
      '800': '#652F11',
      '900': '#461D06',
    },
    error: {
      '50': '#FEE4E2',
      '100': '#FFCDC9',
      '200': '#FDAAA4',
      '300': '#F97A71',
      '400': '#F14E42',
      '500': '#DE3124',
      '600': '#BB251A',
      '700': '#9A231A',
      '800': '#6D1E18',
      '900': '#460D09',
    },
    success: {
      '50': '#D0FBE5',
      '100': '#A6F4D0',
      '200': '#6CE9B8',
      '300': '#38D79E',
      '400': '#0DBC83',
      '500': '#03986B',
      '600': '#027A59',
      '700': '#056048',
      '800': '#044333',
      '900': '#012D22',
    },
    grey: {
      '50': '#F3F4F6',
      '100': '#E5E7EB',
      '200': '#C8CDD5',
      '300': '#9EA5B1',
      '400': '#6B7280',
      '500': '#4B5563',
      '600': '#374151',
      '700': '#1F2937',
      '800': '#111827',
      '900': '#030712',
    },
    text: {
      primary: '#111827',
      secondary: '#4B5563',
      disabled: '#4B5563',
    },
    accent: {
      primary: '#1C434F',
      secondary: '#216069',
    },
  },
  components: {
    ResponsiveTable: {},
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 36,
          height: 20,
          padding: 0,
          '& .MuiSwitch-switchBase': {
            padding: 1,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: '#1E7680',
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: '#F3F4F6',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 14,
            height: 14,
          },
          '& .MuiSwitch-track': {
            borderRadius: '10px',
            backgroundColor: '#374151',
            opacity: 1,
            transition:
              'background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          '&:not(.MuiBackdrop-invisible)': {
            backgroundColor: 'rgba(3,7,18,0.5)',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          '&.MuiContainer-maxWidthLg': {
            paddingLeft: 16,
            paddingRight: 16,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
          boxShadow: '0px 12px 24px 0px #1118273D',
          background: '#fff',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontWeight: 600,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '& .MuiTouchRipple-root *': {
            borderRadius: '8px!important',
          },
        },
        sizeSmall: {
          padding: '4px',
        },
        sizeLarge: {
          padding: '12px',
        },
        sizeMedium: {
          padding: '8px',
        },
      },
      variants: [
        {
          props: { color: 'primary' },
          style: {
            backgroundColor: '#1C434F',
            fill: 'white',
            '&:hover': {
              backgroundColor: '#275766',
            },
            '& .MuiTouchRipple-root': {
              color: '#FFFFFF',
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            backgroundColor: '#3CD1D4',
            fill: '#111827',
            '&:hover': {
              backgroundColor: '#20B5BA',
            },
            '& .MuiTouchRipple-root': {
              color: '#444',
            },
          },
        },
        {
          props: { color: 'outlined' },
          style: {
            border: '1px solid #1E4A53',
            fill: '#111827',
            '&:hover': {
              backgroundColor: '#E0F0F5',
            },
            '& .MuiTouchRipple-root': {
              color: '#444',
            },
          },
        },
        {
          props: { color: 'white' },
          style: {
            fill: '#111827',
            '&:hover': {
              backgroundColor: '#E0F0F5',
            },
            '& .MuiTouchRipple-root': {
              color: '#444',
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          fontWeight: 600,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '& .MuiButton-icon': {
            fill: '#111827',
            width: 24,
            height: 24,
            '& svg': {
              width: 24,
              height: 24,
            },
          },
        },
        sizeLarge: {
          fontSize: 16,
          lineHeight: '24px',
          padding: '12px 16px',
        },
        sizeMedium: {
          fontSize: 16,
          lineHeight: '24px',
          padding: '8px 12px',
        },
        sizeSmall: {
          fontSize: 14,
          lineHeight: '21px',
          padding: '0px 8px',
          minHeight: 32,
          '& .MuiButton-icon': {
            width: 20,
            height: 20,
            '& svg': {
              width: 20,
              height: 20,
            },
          },
          '& .MuiButton-startIcon': {
            marginRight: '4px',
          },
          '& .MuiButton-endIcon': {
            marginLeft: '4px',
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            fontWeight: 500,
            '& .MuiButton-icon': {
              fill: '#fff',
            },
            '&.Mui-disabled': {
              backgroundColor: '#E5E7EB',
              '& .MuiButton-icon': {
                fill: '#9EA5B1',
              },
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            '&.Mui-disabled': {
              backgroundColor: '#E5E7EB',
              '& .MuiButton-icon': {
                fill: '#9EA5B1',
              },
            },
          },
        },
        {
          props: { variant: 'contained', color: 'white' },
          style: {
            '& .MuiTouchRipple-root': {
              color: '#337F96',
            },
            '&:hover': {
              backgroundColor: '#CBE4EC',
            },
            '&.Mui-disabled': {
              backgroundColor: '#4B5563',
              color: '#9EA5B1',
              '& .MuiButton-icon': {
                fill: '#9EA5B1',
              },
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            border: '1px solid #1C434F',
            backgroundColor: '#FFF',
            '&:hover': {
              backgroundColor: '#E0F0F5',
            },
            '& .MuiButton-icon': {
              fill: '#FFF',
            },
            '&.Mui-disabled .MuiButton-icon': {
              fill: '#9EA5B1',
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'white' },
          style: {
            fontWeight: 500,
            border: '1px solid #FFF',
            '&:hover': {
              backgroundColor: '#ffffff17',
            },
            '& .MuiButton-icon': {
              fill: '#FFF',
            },
            '& .MuiLoadingButton-loadingIndicator': {
              color: '#FFF',
            },
            '&.Mui-disabled': {
              border: '1px solid #4B5563',
              color: '#6B7280',
              '& .MuiButton-icon': {
                fill: '#6B7280',
              },
            },
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            '&:hover': {
              backgroundColor: '#E0F0F5',
            },
            '& .MuiButton-icon': {
              fill: '#1C434F',
            },
            '&.Mui-disabled .MuiButton-icon': {
              fill: '#9EA5B1',
            },
          },
        },
        {
          props: { variant: 'text', color: 'white' },
          style: {
            '&:hover': {
              backgroundColor: '#ffffff17',
            },
            '& .MuiTouchRipple-root': {
              color: '#FFFFFF4D',
            },
            '& .MuiButton-icon': {
              fill: '#FFF',
            },
            '&.Mui-disabled': {
              '& .MuiButton-icon': {
                fill: '#6B7280',
              },
              color: '#6B7280',
            },
          },
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.text-input-label': {
            fontSize: '14px',
            lineHeight: '22px',
            color: '#4B5563',
          },
          '&.MuiOutlinedInput-disabled': {
            opacity: 0.6,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: 6,
          '& .MuiOutlinedInput-input': {
            borderRadius: '8px',
            '&::placeholder': {
              opacity: 1,
              fontSize: '16px',
              lineHeight: '24px',
            },
          },
          '& .MuiOutlinedInput-root': {
            '& + .MuiFormHelperText-root': {
              position: 'inherit',
              margin: '6px 0 0',
              fontSize: '14px',
              lineHeight: '22px',
              color: '#4B5563',
            },
            '& + .MuiFormHelperText-root.Mui-disabled': {
              opacity: 0.6,
            },
            '& .MuiOutlinedInput-input:not(.MuiSelect-select)': {
              padding: '8px 12px',
            },
            '& .MuiOutlinedInput-input.MuiSelect-select': {
              padding: '8px 34px 8px 12px',
            },
            '& .MuiOutlinedInput-input.Mui-disabled': {
              opacity: 0.6,
            },
            '& .MuiSelect-icon': {
              top: 'calc(50% - 12px)',
            },
            '& .MuiSelect-iconOpen': {
              top: 'calc(50% - 18px)',
            },
            '& fieldset.MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px',
              border: '1px solid #C8CDD5',
            },
            '&.Mui-error': {
              '& fieldset.MuiOutlinedInput-notchedOutline': {
                border: '1px solid #F14E42',
              },
              '& + .MuiFormHelperText-root': {
                color: '#DE3124',
                fontSize: '15px',
                lineHeight: '24px',
              },
            },
            '&.Mui-error.Mui-focused fieldset.MuiOutlinedInput-notchedOutline':
              {
                border: '2px solid #F14E42',
              },
            '&.Mui-success': {
              '& fieldset.MuiOutlinedInput-notchedOutline': {
                border: '1px solid #0DBC83',
              },
              '& + .MuiFormHelperText-root': {
                color: '#027A59',
              },
              '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
                border: '2px solid #0DBC83',
              },
            },

            '&.Mui-focused:not(.Mui-error):not(.Mui-success) fieldset.MuiOutlinedInput-notchedOutline':
              {
                border: '2px solid #20B5BA',
              },
          },
        },
      },
      variants: [
        {
          props: { color: 'white' },
          style: {
            '& .MuiOutlinedInput-root.MuiInputBase-colorWhite': {
              '& .MuiOutlinedInput-input': {
                color: 'white',
                '&::placeholder': {
                  color: '#9EA5B1',
                },
              },

              '& fieldset.MuiOutlinedInput-notchedOutline': {
                border: '1px solid #6B7280',
              },

              '&.Mui-disabled fieldset.MuiOutlinedInput-notchedOutline': {
                opacity: 0.6,
              },

              '&.Mui-error + p.MuiFormHelperText-root.Mui-error': {
                color: '#F97A71',
              },

              '& + p.MuiFormHelperText-root': {
                color: '#9EA5B1',
              },

              '&.Mui-error fieldset.MuiOutlinedInput-notchedOutline': {
                border: '1px solid #F97A71',
              },
              '&.Mui-error.Mui-focused fieldset.MuiOutlinedInput-notchedOutline':
                {
                  border: '2px solid #F97A71',
                },

              '&.Mui-success + .MuiFormHelperText-root': {
                color: '#38D79E',
              },
            },
          },
        },
      ],
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: '11px 16px 11px 20px',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& > .MuiBox-root': {
            backgroundColor: '#fff',
            boxShadow: '0px 12px 24px 0px #1118273D',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 8px 16px 0px #1118271F',
          border: '1px solid #E5E7EB',
          borderRadius: '12px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#9EA5B1',
          '&.Mui-checked': {
            color: '#1E95A0',
          },
          '&.Mui-disabled': {
            opacity: 0.7,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: '#9EA5B1',
          '&.Mui-checked': {
            color: '#1E95A0',
            '& .MuiSvgIcon-root:last-of-type': {
              transform: 'scale(.8)!important',
            },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: '#1C434F',
          boxShadow: 'none',
          '& .MuiToolbar-root': {
            background: '#1C434F',
            justifyContent: 'space-between',
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },
    },
  },
  typography: {
    fontWeightBold: 600,
    fontFamily: ['Inter', 'Roboto', 'Helvetica Neue', 'sans-serif'].join(','),
    h3: {
      fontSize: 18,
      lineHeight: '27px',
      fontWeight: 500,
      fontFamily: ['Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'].join(
        ','
      ),
    },
    h4: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
      fontFamily: ['Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'].join(
        ','
      ),
    },
    h5: {
      fontSize: 14,
      lineHeight: '22px',
      fontWeight: 500,
      fontFamily: ['Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'].join(
        ','
      ),
    },
    h6: {
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 500,
      fontFamily: ['Epilogue', 'Roboto', 'Helvetica Neue', 'sans-serif'].join(
        ','
      ),
    },
    subtitle2: {
      fontSize: 21,
      lineHeight: '32px',
    },
    body1: {
      fontSize: 16,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      lineHeight: '22px',
    },
    caption: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
});

const epilogueFont = [
  'Epilogue',
  'Roboto',
  'Helvetica Neue',
  'sans-serif',
].join(',');

z1Theme.components!.MuiCard = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      padding: 24,
      borderRadius: '16px',
      '&.bg-card': {
        [z1Theme.breakpoints.down('sm')]: {
          borderRadius: 0,
          marginRight: -16,
          marginLeft: -16,
          padding: 20,
        },
      },
    },
  },
};

z1Theme.typography.h1 = {
  fontWeight: 600,
  fontFamily: epilogueFont,
  fontSize: 28,
  lineHeight: '39px',
  [z1Theme.breakpoints.up('sm')]: {
    fontSize: 32,
    lineHeight: '45px',
  },
};

z1Theme.typography.h2 = {
  fontWeight: 600,
  fontFamily: epilogueFont,
  fontSize: 22,
  lineHeight: '30px',
  [z1Theme.breakpoints.up('sm')]: {
    fontSize: 24,
    lineHeight: '33px',
  },
};

z1Theme.typography.subtitle1 = {
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 400,
  [z1Theme.breakpoints.up('sm')]: {
    fontSize: 18,
    lineHeight: '27px',
  },
};
