// @flow
import * as React from 'react';
import { Card, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDeviceWidth } from '@coverright/utils';
import { usePdpPlanFilter } from './api/use-pdp-plan-filter';
import { PdpQuoteQuery, usePdpFiltersQuery } from '@coverright/data-access/medicare';
import { FilterCheckbox } from '@coverright/features';

type PdpFiltersProps = {
  quote: PdpQuoteQuery['pdpQuote'];
  onChange: (values: Form) => void;
};

export const PdpFilters = (props: PdpFiltersProps) => {
  const {data} = usePdpFiltersQuery();
  const companies = data?.pdpFilters?.companies || [];
  const phone = useDeviceWidth('md');

  const formik = useFormik<Form>({
    initialValues: { companies },
    validationSchema: yup.object(schemaFields),
    onSubmit: () => {},
  });

  const filters = usePdpPlanFilter(props.quote);

  const toggleValue = async (fieldName: keyof Form, name: string, value: boolean) => {
    let newValue: any;

    if (Array.isArray(formik.values[fieldName])) {
      const arrayWithoutValue = _.without(formik.values.companies, name);
      newValue = value ? [...arrayWithoutValue, name] : arrayWithoutValue;
    }

    const newValues = { ...formik.values, [fieldName]: newValue };
    formik.setFieldValue(fieldName, newValue);
    props.onChange(newValues);
  };

  return (
    <Card sx={{ minWidth: 260, p: { xs: 0, md: 3 } }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          {!phone && (
            <Typography variant={'h3'}>Filter your results</Typography>
          )}

          <div>
            <Typography sx={{ fontWeight: 500, mb: 1 }}>Insurance</Typography>
            {filters.companies.map((item, index) => (
              <FilterCheckbox
                labelSx={{
                  '& .MuiFormControlLabel-label': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: { xs: 'calc(100% - 50px)', md: 141 },
                    textOverflow: 'ellipsis',
                  },
                  maxWidth: { xs: 'calc(100% - 25px)', md: undefined },
                }}
                key={item.value}
                name={'companies'}
                checked={formik.values.companies.includes(item.value as any)}
                onChange={(value) => toggleValue('companies', item.value, value)}
                disabled={
                  item.disabled &&
                  !formik.values.companies.includes(item.value as any)
                }
                label={item.label}
                count={item.count}
              />
            ))}
          </div>
        </Stack>
      </form>
    </Card>
  );
};

interface Form {
  companies: string[];
}

const schemaFields = {
  companies: yup.array(),
};
