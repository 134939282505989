// @flow
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Box, SxProps } from '@mui/material';
import { Info } from '@phosphor-icons/react';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { Tooltip } from '@coverright/ui/inputs';

type Props = {
  tooltip?: React.ReactNode;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
  sx?: SxProps;
  iconProps?: {
    size?: number;
    color?: string;
    style?: React.CSSProperties;
  };
  component?: React.ElementType;
};

export function Tooltiped(props: PropsWithChildren<Props>) {
  return (
    <Box
      component={props.component}
      sx={{ position: 'relative', width: 'fit-content', ...(props.sx || {}) }}
    >
      {props.children}
      <Tooltip title={props.tooltip || ''} arrow>
        {props.tooltip ? (
          <Info
            style={{
              position: 'absolute',
              right: -19,
              top: 0,
              ...(props.iconProps?.style || {}),
            }}
            size={props.iconProps?.size ?? 16}
            color={props.iconProps?.color ?? '#1C434F'}
          />
        ) : (
          <></>
        )}
      </Tooltip>
    </Box>
  );
}
