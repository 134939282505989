// @flow
import * as React from 'react';
import { Card, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useMgPlanFilter } from './api/use-mg-plan-filter';
import { MedigapPlanName, MedigapQuoteQuery } from '@coverright/data-access/types/medigap';
import { useEffect } from 'react';
import { useDeviceWidth } from '@coverright/utils';
import { FilterCheckbox } from '@coverright/features';

type MgFiltersProps = {
  quote: MedigapQuoteQuery['medigapQuote'];
  onChange: (values: Form) => void;
};

export const MgFilters = (props: MgFiltersProps) => {
  const {quote, onChange} = props;
  const phone = useDeviceWidth('sm');

  const formik = useFormik<Form>({
    initialValues: {
      planNames: quote?.medigapFilterState.planNames || [],
      companies: quote?.medigapFilterState.companies || [],
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: () => {},
  });

  const filters = useMgPlanFilter(
    quote,
    formik.values.planNames,
    formik.values.companies
  );

  useEffect(() => {
    formik.setFieldValue('planNames', quote?.medigapFilterState.planNames);
  }, [quote?.medigapFilterState.planNames]);

  const toggleValue = async (
    fieldName: keyof Form,
    name: string | MedigapPlanName,
    value: boolean
  ) => {
    const arrayWithoutValue = _.without(
      formik.values[fieldName] as string[],
      name
    );
    const newValue = value ? [...arrayWithoutValue, name] : arrayWithoutValue;
    const newValues = { ...formik.values, [fieldName]: newValue };
    formik.setFieldValue(fieldName, newValue);
    onChange(newValues);
  };

  return (
    <Card sx={{ minWidth: 260, p: { xs: 0, sm: 3 } }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          {!phone && (
            <Typography variant={'h3'}>Filter your results</Typography>
          )}

          {Object.keys(filters).map((filterName, index) => (
            <div key={filterName}>
              <Typography sx={{ fontWeight: 500, mb: 1 }}>
                {titles[index]}
              </Typography>
              {filters[filterName as keyof typeof filters].map(
                (item, index) => (
                  <FilterCheckbox
                    key={item.value}
                    name={filterName}
                    checked={formik.values[
                      filterName as keyof typeof filters
                    ].includes(item.value as any)}
                    onChange={(value) =>
                      toggleValue(
                        filterName as keyof typeof filters,
                        item.value,
                        value
                      )
                    }
                    disabled={
                      item.disabled &&
                      !formik.values[
                        filterName as keyof typeof filters
                      ].includes(item.value as any)
                    }
                    label={item.label}
                    count={item.count}
                  />
                )
              )}
            </div>
          ))}
        </Stack>
      </form>
    </Card>
  );
};

interface Form {
  planNames: MedigapPlanName[];
  companies: string[];
}

const schemaFields = {
  planNames: yup.array(),
  companies: yup.array(),
};

const titles = ['Plan type', 'Company'];
