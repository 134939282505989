// @flow
import * as React from 'react';
import { DrugPriceOutput, IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { TitleRow } from '../../ma-plan-details/pdf/lib';
import { Text } from '@react-pdf/renderer';
import { styles } from '@coverright/shared/pdf';
import { Row, TableCell, TableHeader } from './lib';
import * as _ from 'lodash';
import moment from 'moment';

type Props = {
  plan: IDrugCostBreakdownPlan;
  drugPrices: DrugPriceOutput[];
};

export function DrugCostBreakdownPdfTemplateSummaryByMonth({plan, drugPrices}: Props) {
  const groupedCosts = _.groupBy(plan?.drugCostMonthlyBreakdown?.costs
    .filter((d) => !drugPrices.length || drugPrices.some(dp => dp.rxcui === d.rxcui))
    .map((c) => ({
      ...c,
      numericCost:
        plan?.drugPrices.find((d) => d.rxcui === c.rxcui)?.quoteQuantity ||
        0,
      packageName:
        plan?.drugPrices.find((p) => p.rxcui === c.rxcui)
          ?.packageDescription || c.packageName,
    })) || [], 'month');

  const monthlyCosts = plan?.drugCostMonthlyBreakdown?.monthlyCosts;

  return (
    <>
      <TitleRow title={'Plan and drugs summary'} />

      <Row>
        <TableHeader style={{width: '40%'}}>Month</TableHeader>
        <TableHeader>Quantity</TableHeader>
        <TableHeader>Cost</TableHeader>
        <TableHeader>Coverage phase</TableHeader>
      </Row>

      {Object.keys(groupedCosts).map(month => (
        <React.Fragment key={month}>
          <Row style={{backgroundColor: '#FAF5F0'}}>
            <TableCell style={{width: '40%'}}>
              <Text style={[styles.text, {fontWeight: 'semibold'}]}>
                {moment(month.toString(), 'M').format('MMMM')}
              </Text>
            </TableCell>
            <TableCell/>
            <TableCell>
              <Text style={styles.text}>{
                monthlyCosts?.find((mc) => mc.month.toString() == month)
                  ?.amountString
              }</Text>
            </TableCell>
            <TableCell>
              <Text style={styles.text}>{_.upperFirst(
                _.lowerCase(
                  monthlyCosts?.find((mc) => mc.month.toString() == month)
                    ?.stage
                )
              ) || ''}</Text>
            </TableCell>
          </Row>
          {groupedCosts[month].map((cost, i) => (
            <Row key={i}>
              <TableCell style={{width: '40%'}}>
                <Text style={[styles.text, {fontWeight: 'medium'}]}>{cost.packageName}</Text>
              </TableCell>
              <TableCell>
                <Text style={styles.text}>{cost.numericCost}</Text>
              </TableCell>
              <TableCell>
                <Text style={styles.text}>{cost.cost}</Text>
              </TableCell>
            </Row>
          ))}
        </React.Fragment>
      ))}
    </>
  );
}



