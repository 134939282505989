import * as React from 'react';
import { PropsWithChildren } from 'react';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { Box, SxProps, Typography } from '@mui/material';
import { DrugCostBreakdownVariant } from '../../lib/types';
import { getCatastrophicCoverageExplanation, getInitialCoverageExplanation } from '../../lib/lib';

interface DCBCoverageProps {
  plan: IDrugCostBreakdownPlan;
  variant?: DrugCostBreakdownVariant;
}

export default function Coverage({ plan, variant }: DCBCoverageProps) {
  const initialCoverage = getInitialCoverageExplanation(plan);
  const catastrophicCoverage = getCatastrophicCoverageExplanation(plan);

  return (
    <>
      <Section
        title={'Initial Coverage'}
        variant={variant}
        color={'#EFEBE1'}
        {...initialCoverage}
      />
      <Section
        title={'Catastrophic Coverage'}
        variant={variant}
        color={'#FCF2C9'}
        {...catastrophicCoverage}
      />
    </>
  );
}

interface SectionProps {
  title: string;
  label: React.ReactNode;
  content: React.ReactNode;
  variant?: DrugCostBreakdownVariant;
  color: string;
}

const Section = ({ title, label, content, variant, color }: SectionProps) => {
  return (
    <Box sx={{
      borderLeft: variant === 'z1' ? `8px solid ${color}` : undefined,
      pl: variant === 'z1' ? 3 : undefined
    }}>
      {['z1', 'v3'].includes(variant as string) && (
        <Typography variant={'h4'} sx={{ fontWeight: 600 }}>
          {title}
        </Typography>
      )}
      <Label variant={variant}> {label} </Label>
      <Content variant={variant}> {content} </Content>
    </Box>
  );
};

const Label = ({ variant, children }: PropsWithChildren<{ variant?: DrugCostBreakdownVariant }>) => {
  const sx: SxProps = {
    color: variant === 'v3' ? '#666666' : undefined,
    fontWeight: 600,
    fontSize: variant !== 'v3' ? 14 : undefined,
    lineHeight: !variant ? '20px' : undefined
  };

  return <Typography sx={sx}>{children}</Typography>;
};

const Content = ({ variant, children }: PropsWithChildren<{ variant?: DrugCostBreakdownVariant }>) => {
  const sx: SxProps = {
    color: variant === 'v3' ? '#666666' : variant === 'z1' ? 'text.secondary' : undefined,
    fontSize: !variant ? 13 : variant === 'z1' ? 14 : undefined,
    lineHeight: !variant ? '20px' : undefined
  };

  return <Typography sx={sx}>{children}</Typography>;
};
