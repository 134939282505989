// @flow
import * as React from 'react';
import {
  Button,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { GetDefaultPlansYear, tooltips } from '@coverright/utils';
import { Link } from 'react-router-dom';
import { MaSavePlanButton } from './ma-save-plan-button';
import { MaPlanDetailsButton, MaDrugCostBreakdownButton } from '../../..';
import { MaPlan } from '../../api/use-ma-plans';
import { Tooltiped } from '@coverright/features';
import { useLinkRoute, useQuoteId } from '@coverright/shared/contexts';

type Props = {
  plan: MaPlan;
};

export function MaPlanListItemPrices({ plan }: Props) {
  const link = useLinkRoute('you have to provide doctors drugs route to LinkRouteContext');
  const quoteId = useQuoteId();
  const showDrugCostButton =
    typeof plan.monthlyCost === 'string' &&
    !!plan.drugsCoverage?.length &&
    !plan.missingFormularyInAep;

  const drugsWithError = plan.drugPrices.filter(d => d.error).map(d => d.name);

  return (
    <Wrapper>
      <Stack
        direction={{ xs: 'row', md: 'column' }}
        spacing={{ xs: 2, md: 0 }}
        justifyContent={'space-between'}
      >
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: { xs: 34, md: 40 },
            lineHeight: 1,
            fontWeight: 700,
          }}
        >
          {plan.monthlyCost || 'N/A'}
        </Typography>
        <Tooltiped
          sx={{ alignSelf: 'center' }}
          tooltip={tooltips.maMonthlyCost}
        >
          <Typography
            variant={'body2'}
            sx={{ mt: { md: 1 }, textAlign: { xs: 'end', md: 'start' } }}
          >
            <b>Est. Avg. total cost /mo</b>
          </Typography>
        </Tooltiped>
      </Stack>

      <Stack my={3} spacing={1}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant={'body2'} className={'medium'}>
            Monthly premium:
          </Typography>
          <Typography variant={'body2'}>
            <b>{plan.monthlyPremium}</b>
          </Typography>
        </Stack>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Typography variant={'body2'} className={'medium'}>
            Est. drug costs:
          </Typography>
          <Typography variant={'body2'}>
            <b>{plan.drugsCost || 'N/A'}</b>
          </Typography>
        </Stack>
      </Stack>

      {!!drugsWithError.length && <Typography variant={'body2'}>
        Excl. {drugsWithError.join(', ')}
      </Typography>}

      <Tooltiped tooltip={tooltips.exclOptionalBenefits}>
        <Typography variant={'body2'}>
          Excl. standard Part B premium
          <br />
          Excl. optional benefits
        </Typography>
      </Tooltiped>

      <Stack spacing={3} justifyContent={'flex-end'} flex={1}>
        {(quoteId && showDrugCostButton) && (
          <MaDrugCostBreakdownButton
            variant={'text'}
            sx={{ px: 0, textDecoration: 'underline' }}
            size={'large'}
            bidId={plan.bidId}
            quoteId={quoteId}
            planYear={plan.planYear || GetDefaultPlansYear()}
            id={'ma-plan-drug-cost-breakdown-button'}
          >
            Drug cost summary
          </MaDrugCostBreakdownButton>
        )}
        {!plan.drugsCoverage?.length && (
          <Button
            variant={'text'}
            sx={{ px: 0, textDecoration: 'underline' }}
            size={'large'}
            id={'ma-plan-add-drugs-button'}
            component={Link}
            to={link + '?tab=drugs'}
          >
            Add drugs
          </Button>
        )}
        <MaPlanDetailsButton
          variant={'outlined'}
          plan={plan}
          sx={{ display: { xs: 'none', md: 'inline-flex' } }}
          size={'large'}
        >
          Plan details
        </MaPlanDetailsButton>
        <MaSavePlanButton
          size={'large'}
          sx={{ display: { xs: 'none', md: 'inline-flex' } }}
          plan={plan}
        />
      </Stack>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: 248,
  },
  padding: '32px 24px',
  background: 'rgba(28,67,79,0.06)',
  width: '100%',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
}));
