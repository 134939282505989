import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'Inter',
  fonts: [{
    src: 'https://fonts.cdnfonts.com/s/19795/Inter[slnt,wght].woff',
    fontWeight: 'normal',
  }, {
    src: 'https://fonts.cdnfonts.com/s/19795/Inter-Medium.woff',
    fontWeight: 'medium',
  }, {
    src: 'https://fonts.cdnfonts.com/s/19795/Inter-SemiBold.woff',
    fontWeight: 'semibold',
  }, {
    src: 'https://fonts.cdnfonts.com/s/19795/Inter-Bold.woff',
    fontWeight: 'bold',
  }]
});

Font.register({
  family: 'Epilogue',
  fonts: [{
    src: 'https://fonts.cdnfonts.com/s/46635/Epilogue[wght].woff',
    fontWeight: 'normal',
  }, {
    src: 'https://fonts.cdnfonts.com/s/46635/Epilogue-Medium.woff',
    fontWeight: 'medium',
  }, {
    src: 'https://fonts.cdnfonts.com/s/46635/Epilogue-SemiBold.woff',
    fontWeight: 'semibold',
  }]
});

export const styles = StyleSheet.create({
  text: {
    fontSize: '10px',
    lineHeight: '13px',
    fontFamily: 'Inter',
    textAlign: 'left'
  },
})
