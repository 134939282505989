// @flow
import * as React from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { RowBuilderProps } from '../../../lib/row-builder';
import { RowTitle } from '../../row-title';
import { MedigapPlanOutputWithDetails } from '@coverright/data-access/medigap';
import { getNumbersFromString, tooltips } from '@coverright/utils';
import { MedigapPlanName } from '@coverright/data-access/types/medigap';
import { Benefit } from '@coverright/ui/marketplaces';

type Props = {
  TableRow: React.FC<RowBuilderProps<MedigapPlanOutputWithDetails>>;
};

export function KeyPlanBenefits({ TableRow }: Props) {
  return (
    <>
      <TableRow
        title={
          <RowTitle
            title={'Copays / Coinsurance'}
            tooltip={tooltips.mgCopaysCoinsurance}
          />
        }
        builder={(plan) => (
          <Typography variant={'caption'}>{plan.partBServices}</Typography>
        )}
      />
      <TableRow
        title={<RowTitle title={'Deductibles'} />}
        builder={(plan) => (
          <Stack spacing={0.5}>
            {[MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(
              plan.planName as MedigapPlanName
            ) && (
              <Typography variant={'caption'}>
                <b>{plan.deductibles.partA}</b> annual deductible
              </Typography>
            )}
            {![MedigapPlanName.Hdf, MedigapPlanName.Hdg].includes(
              plan.planName as MedigapPlanName
            ) && (
              <Typography variant={'caption'}>
                <b>{plan.deductibles.partA}</b> Hospital (Part A deductible)
              </Typography>
            )}
            {plan.deductibles.partB && (
              <Typography variant={'caption'}>
                <b>{plan.deductibles.partB}</b> Medical (Part B deductible)
              </Typography>
            )}
          </Stack>
        )}
      />

      <TableRow
        title={<RowTitle title={'Plan benefits'} />}
        builder={(plan) => (
          <Stack spacing={1}>
            <Benefit
              label={
                <BenefitLabel
                  sx={{
                    color: plan?.features.partADeductible
                      ? 'success.600'
                      : 'error.600',
                  }}
                >
                  Short term hospital <span>(Part A deductible)</span>
                </BenefitLabel>
              }
              value={!!plan?.features.partADeductible}
            />

            <Benefit
              label={
                <BenefitLabel
                  sx={{
                    color: plan?.features.partACopay
                      ? 'success.600'
                      : 'error.600',
                  }}
                >
                  Long term hospital <span>(Part A copay)</span>
                </BenefitLabel>
              }
              value={!!plan?.features.partACopay}
            />

            <Benefit
              label={
                <BenefitLabel
                  sx={{
                    color: plan?.features.partBDeductible
                      ? 'success.600'
                      : 'error.600',
                  }}
                >
                  Medical deductible <span>(Part B deductible)</span>
                </BenefitLabel>
              }
              value={!!plan?.features.partBDeductible}
            />

            <Benefit
              label={
                <BenefitLabel
                  sx={{
                    color: plan?.features.partBExcessCharges
                      ? 'success.600'
                      : 'error.600',
                  }}
                >
                  Medical excess charges <span>(Part B excess)</span>
                </BenefitLabel>
              }
              value={!!plan?.features.partBExcessCharges}
            />

            <Benefit
              label={
                <BenefitLabel
                  sx={{
                    color: plan?.features.skilledNursingFacility
                      ? 'success.600'
                      : 'error.600',
                  }}
                >
                  Skilled nursing facility
                </BenefitLabel>
              }
              value={!!plan?.features.skilledNursingFacility}
            />

            <Benefit
              label={
                <BenefitLabel
                  sx={{
                    color: plan?.features.foreignTravelEmergency
                      ? 'success.600'
                      : 'error.600',
                  }}
                >
                  Foreign travel emergency
                </BenefitLabel>
              }
              value={!!plan?.features.foreignTravelEmergency}
            />
          </Stack>
        )}
      />

      <TableRow
        title={<RowTitle title={'Ratings'} />}
        builder={(plan) => (
          <Stack spacing={1}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant={'caption'} className={'w-100'}>
                <b>AM Best rating:</b>
              </Typography>
              <Typography
                sx={{ color: 'success.600' }}
                className={'fs-12 lh-18 medium'}
              >
                {plan.amBestRating || 'n.a.'}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography variant={'caption'} className={'w-100'}>
                <b>S&P rating:</b>
              </Typography>
              <Typography
                sx={{ color: 'success.600' }}
                className={'fs-12 lh-18 medium'}
              >
                {plan.spRating || 'n.a.'}
              </Typography>
            </Stack>
          </Stack>
        )}
      />

      <TableRow
        title={<RowTitle title={'Household discount'} />}
        builder={(plan) => (
          <Typography variant={'caption'}>
            {plan.householdDiscount ? (
              <>
                {plan.householdDiscount} <span className="mh-5">|</span> $
                {(
                  (1 - getNumbersFromString(plan.householdDiscount)[0] / 100) *
                  getNumbersFromString(plan.monthlyPremium as string)[0]
                ).toFixed(2)}{' '}
                /mo
              </>
            ) : (
              'n.a.'
            )}
          </Typography>
        )}
      />
    </>
  );
}

const BenefitLabel = styled(Typography)({
  marginTop: 2,
  fontSize: 12,
  lineHeight: '13px',
  marginLeft: 8,
  whiteSpace: 'nowrap',
  fontWeight: 500,
  '& span': {
    fontWeight: 400,
    color: '#111827',
    fontSize: 11,
  },
});
