import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { Benefit, DrugCoverageList } from '@coverright/ui/marketplaces';
import { getInitialTooltipByTier, mapDeliveryType, useDeviceWidth } from '@coverright/utils';
import { ResponsiveTable, ResponsiveTableColumn } from '@coverright/features';
import {
  DrugTierOutput,
  PdpPlanListQuery, PlanYear
} from '@coverright/data-access/medicare';
import * as _ from 'lodash';
import { Info } from '@phosphor-icons/react';
import * as React from 'react';
import { PdpSavePlanButton } from './pdp-save-plan-button';
import { Link } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { useLinkRoute } from '@coverright/shared/contexts';

type Props = {
  plan: PdpPlanListQuery['PdpPlans']['data'][0];
};

export const PdpPlanListItemDetails = ({ plan }: Props) => {
  const link = useLinkRoute('you have to provide doctors drugs route to LinkRouteContext');
  const phone = useDeviceWidth('md');
  return (
    <Box mb={2}>
      <Typography variant={'h3'} sx={{ background: '#F0F7FA', p: 1.5 }}>
        Additional Details
      </Typography>

      <Row title={'Plan Type'}>
        <Typography>Prescription Drug Plan (PDP)</Typography>
      </Row>

      <Row title={'Your Prescriptions'}>
        <DrugCoverageList
          labelClassName={'text-primary fs-14 mb-8'}
          hideTitle
          coverage={plan.drugsCoverage}
        />
        <div className={`h-8`} />
        <Button
          className={'w-140'}
          variant={'outlined'}
          size={'small'}
          sx={{ px: 3 }}
          component={Link}
          to={(link || '') + '?tab=drugs'}
        >
          {plan?.drugsCoverage?.length ? 'Edit' : 'Add'} drugs
        </Button>
      </Row>

      <Row title={'Your Pharmacy'}>
        {plan?.quoteDrugsTiers?.pharmacyName && (
          <Box display={'flex'} flexGrow={2} mb={'15px'}>
            <Benefit
              label={
                plan?.quoteDrugsTiers?.pharmacyName +
                ` (${mapDeliveryType(plan?.quoteDrugsTiers?.deliveryType)})`
              }
              value={true}
              labelClass={'regular color-primary'}
            />
          </Box>
        )}
        <Button
          variant={'outlined'}
          className={'w-140'}
          size={'small'}
          component={Link}
          to={(link || '') + '?tab=pharmacy'}
        >
          {plan?.quoteDrugsTiers?.pharmacyName ? 'Change' : 'Add'} Pharmacy
        </Button>
      </Row>

      <Row title={'Prescription Drug Deductible'}>
        {[
          plan?.drugDetails?.mrxAltDedAmount || '',
          plan?.drugDetails?.mrxAltNoDedTier || '',
        ]
          .filter(Boolean)
          .map((value) => (
            <Typography>{value}</Typography>
          ))}
      </Row>

      <Row title={'Initial Coverage Limit'}>
        {plan.drugDetails?.initialCoverageLimit}
      </Row>

      {plan.planYear === PlanYear.Year2024 && <Row title={'Coverage Gap (\'Donut Hole\') Limit'}>
        {plan.drugDetails?.catastrophicThreshold}
      </Row>}

      <Row title={'Initial coverage breakdown'}>
        <></>
      </Row>

      <ResponsiveTable
        sx={{
          tableLayout: 'auto!important',
          '& tr > td:first-of-type': { backgroundColor: '#FFF' },
        }}
        columns={getTableColumns()}
        rows={tiers.map((tier) =>
          [
            plan.drugsTiers30Preferred,
            plan.drugsTiers30Standard,
            plan.drugsTiers90Preferred,
            plan.drugsTiers90Standard,
          ].map((d) => getTier(tier, d))
        )}
      />

      <Stack sx={{ mt: 2 }} alignItems={'flex-end'}>
        <PdpSavePlanButton
          plan={plan}
          size={phone ? 'large' : 'medium'}
          fullWidth={phone}
        />
      </Stack>
    </Box>
  );
};

export const Row = ({
  title,
  children,
}: PropsWithChildren<{ title: string }>) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      gap: { sm: 2 },
      my: { xs: 2, sm: 0 },
    }}
  >
    <Box flex={{ sm: 2 }} sx={{ p: { sm: 2 }, mb: { xs: 0.5, sm: 0 } }}>
      <Typography>
        <b>{title}</b>
      </Typography>
    </Box>
    <Box flex={{ sm: 3 }} sx={{ p: { sm: 2 } }}>
      {children}
    </Box>
  </Box>
);

type DrugTiersOutput =
  PdpPlanListQuery['PdpPlans']['data'][0]['drugsTiers30Preferred'];

const getTier = (tier: DrugTierOutput, data?: DrugTiersOutput): string =>
  data?.initialCoverageDrugTiers.find((t) => t.tier === tier)?.coverage || '';

const getTableColumns: () => ResponsiveTableColumn<string[]>[] = _.memoize(
  () => {
    const result: ResponsiveTableColumn<string[]>[] = [
      {
        title: '',
        value: (data, i) => (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              width: 'fit-content',
            }}
          >
            <Tooltip
              placement={'right'}
              arrow
              title={getInitialTooltipByTier(tiers[i])}
            >
              <Info color={'#7B8F95'} width={18} height={18} />
            </Tooltip>
            <Typography className={'no-wrap'}>{tierNames[i]}</Typography>
          </Box>
        ),
      },
      {
        title: 'Preferred Retail Pharmacy cost-sharing (30 day supply)',
        value: (data, i) => data[0],
      },
      {
        title: 'Standard Retail Pharmacy cost-sharing (30 day supply)',
        value: (data, i) => data[1],
      },
      {
        title: 'Preferred Mail Order cost-sharing (90 day supply)',
        value: (data, i) => data[2],
      },
      {
        title: 'Standard Mail Order cost-sharing (90 day supply)',
        value: (data, i) => data[3],
      },
    ];

    return result;
  }
);

const tiers = [
  DrugTierOutput.PreferredGeneric,
  DrugTierOutput.Generic,
  DrugTierOutput.PreferredBrand,
  DrugTierOutput.NonPreferredBrand,
  DrugTierOutput.Specialty,
];

const tierNames = [
  'Tier 1: Preferred generic drugs',
  'Tier 2: Generic drugs',
  'Tier 3: Preferred brand drugs',
  'Tier 4: Non-preferred drugs',
  'Tier 5: Specialty drugs',
];
