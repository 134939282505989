// @flow
import * as React from 'react';
import { DrugPriceOutput } from '@coverright/data-access/medicare';
import { TitleRow } from '../../ma-plan-details/pdf/lib';
import { Text } from '@react-pdf/renderer';
import { PdfBenefit, styles } from '@coverright/shared/pdf';
import { Row, TableCell, TableHeader } from './lib';

type Props = {
  drugPrices: DrugPriceOutput[];
};

export function DrugCostBreakdownPdfTemplateDetailedCostCalculation({drugPrices}: Props) {

  return (
    <>
      <TitleRow title={'Detailed cost calculations'} />

      <Row>
        <TableHeader>Drug</TableHeader>
        <TableHeader>Quantity</TableHeader>
        <TableHeader>Estimated full retail price</TableHeader>
        <TableHeader>Covered?</TableHeader>
        <TableHeader>Quantity limit</TableHeader>
        <TableHeader>Branded or generic?</TableHeader>
      </Row>

      {drugPrices.map(price => (
        <Row key={price.packageDescription}>
          <TableCell>
            <Text style={[styles.text, {fontWeight: 'semibold'}]}>
              {price.packageDescription}
            </Text>
          </TableCell>
          <TableCell>
            <Text style={styles.text}>
              {price.quoteQuantity}
            </Text>
          </TableCell>
          <TableCell>
            <Text style={styles.text}>
              {price.price} {price.isAggregatedPrice ? '*' : ''}
            </Text>
          </TableCell>
          <TableCell>
            <PdfBenefit value={price.isCoveredByPlan}
                        label={price.isCoveredByPlan ? 'Yes' : 'No'} />
          </TableCell>
          <TableCell>
            <Text style={styles.text}>{price.quantityLimit || '-'}</Text>
          </TableCell>
          <TableCell>
            <Text style={styles.text}>
              {price.isGeneric ? 'Generic' : 'Branded'}
            </Text>
          </TableCell>
        </Row>
      ))}
    </>
  );
}
