// @flow
import * as React from 'react';
import { Card, Grid } from '@mui/material';
import { Gender, MedigapQuoteQuery } from '@coverright/data-access/types/medigap';
import moment from 'moment';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { MgPersonalDetailsAgeFilter as AgeFilter } from './mg-personal-details-age-filter';
import { MgPersonalDetailsGenderFilter as GenderFilter } from './mg-personal-details-gender-filter';
import { MgPersonalDetailsEffectiveDateFilter as EffectiveDateFilter } from './mg-personal-details-effective-date-filter';
import { MgPersonalDetailsTobaccoFilter as TobaccoFilter } from './mg-personal-details-tobacco-filter';
import { getEffectiveDate } from '@coverright/utils';
import { useSaveMgFilters } from '..';

interface Form {
  age: number;
  gender: Gender;
  tobacco: boolean;
  effectiveDate: string;
}

const schemaFields = {
  age: yup.number(),
  gender: yup.string(),
  tobacco: yup.boolean(),
  effectiveDate: yup.string(),
};

interface Props {
  quote: MedigapQuoteQuery['medigapQuote'];
  birthDate?: string;
}

export function MgPersonalDetailsFilter({quote, birthDate}: Props) {
  const save = useSaveMgFilters(quote);

  if (!birthDate) {
    console.warn('You have to provide client birthDate to calculate proper effectiveDate')
  }

  const formik = useFormik<Form>({
    initialValues: {
      age: quote?.medigapFilterState.age || 64,
      gender: quote?.medigapFilterState.gender || Gender.F,
      tobacco: quote?.medigapFilterState.tobacco || false,
      effectiveDate: getEffectiveDate(
        quote?.medigapFilterState.effectiveDate,
        birthDate,
        'MM/DD/YYYY'
      ),
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: () => {},
  });

  const toggleValue = async (fieldName: keyof Form, value: any) => {
    const newValues = { ...formik.values, [fieldName]: value };
    formik.setFieldValue(fieldName, value);

    const formatEffectiveDate = (date: string) =>
      moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD');

    if (fieldName === 'effectiveDate') {
      const isValidFormat = /\d{2}\/\d{2}\/\d{4}/gm.test(value);
      if (isValidFormat) {
        if (!isEffectiveDateValid(value)) {
          formik.setFieldError('effectiveDate', 'Date is not valid');
          return;
        } else {
          newValues.effectiveDate = formatEffectiveDate(value);
          formik.setFieldError('effectiveDate', undefined); // Clear the error if valid
        }
      } else {
        formik.setFieldError('effectiveDate', 'Invalid date format');
        return;
      }
    } else if (newValues.effectiveDate) {
      // Ensure effectiveDate is formatted properly if it exists
      newValues.effectiveDate = formatEffectiveDate(newValues.effectiveDate);
    }

    save(newValues);
  };

  return (
    <Card sx={{ p: '2px 20px 8px 20px', borderRadius: '8px' }}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={6} lg={3}>
            <AgeFilter
              value={formik.values.age}
              onChange={(value) => toggleValue('age', value)}
            />
          </Grid>
          <Grid item sx={{ display: 'grid', alignItems: 'end' }} xs={6} lg={3}>
            <GenderFilter
              value={formik.values.gender}
              onChange={(value) => toggleValue('gender', value)}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <TobaccoFilter
              value={formik.values.tobacco}
              onChange={(value) => toggleValue('tobacco', value)}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <EffectiveDateFilter
              value={formik.values.effectiveDate}
              onChange={(value) => toggleValue('effectiveDate', value)}
            />
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}

const isEffectiveDateValid = (date?: string): boolean => {
  if (!date) {
    return false;
  }

  return (
    moment(date).isValid() &&
    moment(date).isSameOrAfter(moment().startOf('day'))
  );
};
