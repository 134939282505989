import { DrugCalculationStage, DrugDiscount, DrugDiscountBrand } from '@coverright/data-access/types/medicare';
import * as _ from 'lodash';
import { GetDefaultPlansYear, toCurrency } from '@coverright/utils';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import moment from 'moment/moment';

export const getDrugOfferGetter = (discountedDrugs?: DrugDiscount[] | null) =>
  _.memoize((rxqui: string): DrugDiscount | undefined =>
    discountedDrugs?.find((d) => d.rxcui === rxqui)
  );

export function getDrugDiscount(discount?: DrugDiscount) {
  if (discount?.discountBrand === DrugDiscountBrand.GoodRx) {
    return toCurrency(discount.goodRxCost, 2, 2);
  } else if (discount?.discountBrand === DrugDiscountBrand.CostPlus) {
    return toCurrency(discount?.costPlusCost, 2, 2) + '*';
  } else {
    return '';
  }
}

export const getInitialCoverageExplanation = (plan: IDrugCostBreakdownPlan) => {
  const hit = plan.drugCostMonthlyBreakdown?.coverageEntry.find(
    (entry) => entry.stage === DrugCalculationStage.InitialCoverage
  );
  const mrxAltDedAmount = plan?.drugDetails?.mrxAltDedAmount
  return {
    label: hit ? (
      `You most likely will enter the Initial Coverage Phase this year under this plan based on the information you provided on your prescription drugs.`
    ) : (
      `You most likely won't enter the Initial Coverage Phase this year under this plan based on the information you provided on your prescription drugs.`
    ),
    content: mrxAltDedAmount ?
      `This plan has a drug deductible of ${mrxAltDedAmount}. That means you'll pay the retail drug price for your covered drugs up until that point, before your insurance starts paying.`
      : `This plan doesn't have a drug deductible. That means you'll pay a copay or co-insurance for all of your covered prescription drugs until you reach the Coverage Gap.`
  }
}

export const getCatastrophicCoverageExplanation = (plan: IDrugCostBreakdownPlan) => {
  const hit = plan.drugCostMonthlyBreakdown?.coverageEntry.find(
    (entry) => entry.stage === DrugCalculationStage.Catastrophic
  );
  const year = (plan.planYear || GetDefaultPlansYear()).toString().replace('Year', '')

  return {
    label: hit ? (
      `You most likely will enter the Coverage Gap Phase in ${moment(hit.month.toString(), 'M').format('MMMM')} this year under this plan based on the information you provided on your prescription drugs.`
    ) : (
      `You most likely won't enter the Catastrophic Coverage Phase this year under this plan based on the information you provided on your prescription drugs.`
    ),
    content: `You will enter the Catastrophic Coverage phase once your total out-of-pocket costs reaches ${plan?.drugDetails?.catastrophicThreshold} (in ${year}). Under this phase, you’ll pay the ${plan?.drugDetails?.catastrophicCopayGeneric} of retail price for generic drugs, and ${plan?.drugDetails?.catastrophicCopayBranded} of retail price for branded drugs.`
  }
}
