import * as React from 'react';
import { NodeProps, Path, Svg, Text, View } from '@react-pdf/renderer';
import { styles } from './styles';

type StyleWithoutArray<T> = T extends any[] ? never : T;

interface Props {
  value: boolean,
  label: React.ReactNode,
  inactive?: boolean,
  overrideCrossIconColor?: string,
  style?: StyleWithoutArray<NodeProps['style']>,
}

export const PdfBenefit = ({value, label, inactive, overrideCrossIconColor, style = {}}: Props) => {
  const crossColor = inactive ? 'rgba(28, 67, 79)' : overrideCrossIconColor || '#DD3D3D';
  const labelColor = inactive ? 'rgba(17,24,39,0.5)' : value ? '#029094' : '#DD3D3D';

  return <View style={{display: 'flex', gap: 3, alignItems: 'center', flexDirection: 'row'}}>
    {value ? <PdfCheck /> : <PdfCross color={crossColor} opacity={inactive ? .3 : 1} />}
    <Text style={[styles.text, {color: labelColor, lineHeight: 2}, style]}>{label}</Text>
  </View>
}

export const PdfCross = ({color, opacity = 1}: { color: string, opacity?: number }) => {
  return <Svg width={16} height={16} fill={color} opacity={opacity} viewBox={'0 0 256 256'}>
    <Path d={'M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm37.66,130.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32L139.31,128Z'} />
  </Svg>
}

export const PdfCheck = () => {
  return <Svg width={16} height={16} fill={'#029094'} viewBox={'0 0 256 256'}>
    <Path d={'M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm45.66,85.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z'} />
  </Svg>
}
