import { Box, Button, Card, Typography } from '@mui/material';
import { environment } from '../../environments/environment';
import React from 'react';

export default function OffersCtaCard() {
  return <Card sx={{ p: 4 }}>
    <Typography variant={'h4'}>Are you in the right Medicare plan?</Typography>
    <Box sx={{ display: 'flex', flex: 1, gap: 2, alignItems: 'flex-start', my: 2, }}>
      <img className={'w-40 h-40'} src={'/assets/img/think.png'}/>
      <Typography><b>9 out of 10 people are in the wrong Medicare plan.</b> Try
        CoverRight’s platform to see if you can save.</Typography>
    </Box>
    <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
      <Button data-test={'next_button'}
              variant={'contained'}
              size={'large'}
              fullWidth
              onClick={() => document.location.href = environment.dashboardUrl + 'application-onboarding/zip'}
      >Discover New Plans</Button>
    </Box>
  </Card>
}
