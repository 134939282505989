// @flow
import * as React from 'react';
import { ButtonProps } from '@mui/material';
import {
  ComparablePlan,
  ComparablePlanType,
  PdpPlanListQuery, usePdpQuoteQuery
} from '@coverright/data-access/medicare';
import { GetDefaultPlansYear } from '@coverright/utils';
import { useQuoteId } from '@coverright/shared/contexts';
import { SavePlanButton } from '@coverright/features';

type PdpPlan = PdpPlanListQuery['PdpPlans']['data'][0];

type Props = ButtonProps & {
  plan: PdpPlan;
};

export function PdpSavePlanButton(props: Props) {
  const { plan, ...buttonProps } = props;
  const quoteId = useQuoteId()
  const {data} = usePdpQuoteQuery({variables: {id: quoteId}});
  const quote = data?.pdpQuote;

  if (!quote) {
    console.warn('No medicareQuote found.');
    return null;
  }

  return (
    <SavePlanButton
      {...buttonProps}
      clientId={quote.clientId}
      plan={toComparablePlan(plan, quote.zip!, quote.countyName!)}
    />
  );
}

const toComparablePlan = (
  plan: PdpPlan,
  zip: string,
  county: string
): ComparablePlan => ({
  bidId: plan.bidId,
  zip,
  county,
  planYear: plan.planYear || GetDefaultPlansYear(),
  type: ComparablePlanType.Pdp,
});
