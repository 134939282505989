import * as _ from 'lodash';
import moment from 'moment';
import { getStoredUser } from './StoredUser';
import { DrugCalculationStage, MonthlyTotalCost } from '@coverright/data-access/types/medicare';
import { MedigapPlanName } from '@coverright/data-access/types/medigap';

let customProps: any;

export function getUTMParams(term: string): string {
  const commonProps = {
    utm_content: replaceAll(_.trim(window.parent.location.pathname, '/'), '/', '_'),
    utm_term: term,
    utm_medium: 'widget',
    utm_campaign: 'pilot'
  };

  function convert(props: { [key: string]: string }): string {
    const result: string[] = [];
    for (const key in props) {
      result.push(key + '=' + props[key]);
    }
    return '&' + result.join('&');
  }

  switch (window.parent.document.location.host) {
    case 'www.benzinga.com':
      return convert({ ...commonProps, utm_source: 'benzinga', ...customProps });
    case 'moneywise.com':
      return convert({ ...commonProps, utm_source: 'moneywise', ...customProps });
    case 'www.forbes.com':
      return convert({ ...commonProps, utm_source: 'ForbesHealth', ...customProps });
    case 'forbes.com':
      return convert({ ...commonProps, utm_source: 'ForbesHealth', ...customProps });
    case 'www.retireguide.com/':
      return convert({ ...commonProps, utm_source: 'Retire_Guide', ...customProps });
    case 'gobankingrates.com':
    case 'www.gobankingrates.com':
      return convert({ ...commonProps, utm_source: 'gobankingrates', ...customProps });
    case 'www.rightsure.com':
    case 'rightsure.com':
      return convert({ ...commonProps, utm_source: 'rightsure', ...customProps });
    case 'coverright.com': return '';
    default:
      return convert({ ...commonProps, ...customProps });
  }
}


export function appPropsHandler(props: any) {
  if (props) {
    customProps = props;
  }
}

export const getInitialEnrollmentPeriod = (input = getStoredUser()?.birthDate) => {
  if (input) {
    const birthdate = moment(input).add(65, 'year');
    const start = birthdate.clone();
    const end = birthdate.clone();
    if (birthdate.date() === 1) {
      start.subtract(4, 'months').startOf('month');
      end.add(2, 'months').endOf('month');
    } else {
      start.subtract(3, 'months').startOf('month');
      end.add(3, 'months').endOf('month');
    }
    return { start, end };
  }
  return undefined;
};

export function delegate(el: Document, evt: string, sel: string, handler: (event: any) => void) {
  const listener = (event: any) => {
    let t = event.target as any;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    while (t && t.matches && t !== this) {
      if (t.matches(sel)) {
        handler.call(t, event);
      }
      t = t.parentNode;
    }
  }
  el.addEventListener(evt, listener);
  return listener;
}

export const showRange = (v1?: string | null, v2?: string | null, additional?: string, na?: string): string => {
  let result;
  if (v1 && !v2) {
    result = v1;
  } else if (!v1 && v2) {
    result = v2;
  } else if (!v1 && !v2) {
    return na || 'N/A';
  } else if (v1 === v2) {
    result = v1;
  } else {
    result = v1 + ' - ' + v2;
  }


  return `${result} ${additional || ''}`;
};

export function areComponentPropsEqual<T>(prevProps: T, nextProps: T): boolean {
  return _.isEqual(prevProps, nextProps);
}

enum DrugDeliveryMethod {
  PreferredMail = 'PREFERRED_MAIL',
  PreferredRetail = 'PREFERRED_RETAIL',
  StandardMail = 'STANDARD_MAIL',
  StandardRetail = 'STANDARD_RETAIL'
}

enum Rating {
  None = 'NONE',
  R1 = 'R1',
  R1_5 = 'R1_5',
  R2 = 'R2',
  R2_5 = 'R2_5',
  R3 = 'R3',
  R3_5 = 'R3_5',
  R4 = 'R4',
  R4_5 = 'R4_5',
  R5 = 'R5'
}

export const mapDeliveryType = (type: DrugDeliveryMethod): string => ({
  [DrugDeliveryMethod.PreferredMail]: 'Preferred mail-order pharmacy',
  [DrugDeliveryMethod.StandardMail]: 'Standard mail-order pharmacy',
  [DrugDeliveryMethod.PreferredRetail]: 'Preferred retail pharmacy',
  [DrugDeliveryMethod.StandardRetail]: 'Standard retail pharmacy'
})[type];

export const formatPharmacyName = (name: string) => {
  return _.startCase(name.toLowerCase())
    .replace('Cvs', 'CVS')
    .replace('Ahf', 'AHF')
    .replace('Svrf', 'S.V.R.H.');
};

export const storeFilters = (data: any, zip: string) => {
  const storedFilters = localStorage.getItem('filters');
  let result: any = { zip, companies: [], planTypes: [], rating: [], SNPTypes: [], extraBenefits: [] };
  if (storedFilters) {
    result = JSON.parse(storedFilters);
  }
  Object.keys(data).forEach(key => {
    result[key] = data[key];
  });
  sessionStorage.setItem('filters', JSON.stringify(result));
};

export function handleCollectionChange<T>(element: T, collection: T[]) {

  let out: T[];

  if (!!collection) {

    if (collection.includes(element)) {

      out = _.without(collection, element);

    } else {

      out = [...collection, element];
    }
  } else {

    out = [element];
  }

  return out;
}

export function handleRatingChange(element: Rating, collection: Rating[]) {

  let out: Rating[] = [];

  function addHalfStarRating(r: Rating) {
    switch (r) {
      case Rating.R1:
        return [Rating.R1, Rating.R1_5];
      case Rating.R2:
        return [Rating.R2, Rating.R2_5];
      case Rating.R3:
        return [Rating.R3, Rating.R3_5];

      case Rating.R4:
        return [Rating.R4, Rating.R4_5];
      case Rating.R5:
        return [Rating.R5];
      default:
      case Rating.None:
        return [Rating.None];
    }
  }

  if (collection) {

    if (collection.includes(element)) {

      out = [...collection];
      addHalfStarRating(element).forEach((r) => {

        out = _.without(out, r);
      });

    } else {

      out = _.concat([...collection], addHalfStarRating(element));
    }
  } else {
    out = addHalfStarRating(element);
  }

  return out;
}

export function convertMedium(text: string) {
  return replaceAll(replaceAll(text, '[b]', '<span class="medium">'), '[/b]', '</span>');
}

export function convertBold(text: string) {
  return replaceAll(replaceAll(text, '[b]', '<b>'), '[/b]', '</b>');
}

export function convertList(text: string) {
  return replaceAll(replaceAll(text, '[li]', '<span class="list-item">'), '[/li]', '</span>');
}

function escapeRegExp(string: string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function getAgeByBirthdate(birthdate?: string, format = 'YYYY-MM-DD'): number {
  return Math.floor(moment.duration(moment().diff(moment(birthdate, format))).asYears());
}

export const filterByEligible = (age?: number | string, effectiveDate?: string) => (plan: MedigapPlanName) =>
  isPersonEligible(age, effectiveDate) ? true : ![MedigapPlanName.F, MedigapPlanName.C, MedigapPlanName.Hdf].includes(plan);

export function isPersonEligible(birthdate?: string | number, effectiveDate?: string): boolean {
  // They are not eligible if they turned 65 after Jan 1, 2020
  if (!birthdate) {
    birthdate = getStoredUser()?.birthDate;
  } else if (typeof birthdate === 'number') {
    let age = birthdate;
    if (!age || age >= 63 && age < 65) {
      age = 65;
    }
    birthdate = moment(effectiveDate || undefined).subtract(age, 'years').toISOString();
  }
  if (typeof birthdate === 'string') {
    return Math.floor(moment.duration(moment('Jan 1, 2020').diff(moment(birthdate))).asYears()) >= 65;
  } else {
    console.warn('Birthdate is not found');
    return false;
  }
}

export function getEffectiveDate(value?: string | null, birthDate?: string, format = 'YYYY-MM-DD'): string {
  let effectiveDate;
  if (value) {
    effectiveDate = moment(value);
  } else {
    birthDate = birthDate ? birthDate : getStoredUser()?.birthDate;
    const age = getAgeByBirthdate(birthDate);
    if (age >= 63 && age < 65) {
      effectiveDate = moment(birthDate).add(65, 'years').startOf('month');
    } else {
      effectiveDate = moment().add(1, 'month').startOf('month');
    }
  }

  if (!effectiveDate.isValid() || effectiveDate.isBefore(moment().startOf('day'))) {
    effectiveDate = moment().add(1, 'month').startOf('month');
  }
  return effectiveDate.format(format);
}

export function getNumbersFromString(v: string): number[] {
  const matches = replaceAll(v, ',', '').match(/[\d.]+/g);
  if (matches)
    return matches.map(parseFloat);
  return [];
}

export function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function toCurrency(value: number, minimumFractionDigits = 0, maximumFractionDigits = 0) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency', currency: 'USD',
    minimumFractionDigits,
    maximumFractionDigits
  }).format(value);
}

export const getCompanyLogo = (parentOrgName?: string | null, orgName?: string | null): string => {
  if (!parentOrgName) {
    return '';
  }

  if (!orgName) {
    orgName = '';
  }

  const name = matchParentOrgName(parentOrgName);

  if (name === 'aetna') {
    if ([
      'COVENTRY HEALTH CARE OF VIRGINIA, INC.',
      'COVENTRY HEALTH AND LIFE INSURANCE COMPANY',
      'COVENTRY HEALTH CARE OF ILLINOIS, INC.',
      'COVENTRY HEALTH CARE OF KANSAS, INC.',
      'COVENTRY HEALTH CARE OF MISSOURI, INC',
      'COVENTRY HEALTH CARE OF NEBRASKA, INC.',
      'COVENTRY HEALTH CARE OF WEST VIRGINIA, INC.',
      'FIRST HEALTH LIFE & HEALTH INSURANCE COMPANY',
      'HEALTHASSURANCE PENNSYLVANIA, INC.'
    ].includes(orgName as string)) {
      return '';
    }
    if (orgName === 'Allina Health Aetna Medicare') {
      return 'https://logo.plans.coverrightmedicare.com/allina.png';
    }
    return 'https://logo.plans.coverrightmedicare.com/aetna.png';
  }

  if (name === 'cigna') {
    if ([
      'HEALTHSPRING LIFE & HEALTH INSURANCE COMPANY, INC.',
      'HEALTHSPRING OF FLORIDA, INC.'
    ].includes(orgName as string)) {
      return '';
    }
    return 'https://logo.plans.coverrightmedicare.com/cigna.svg';
  }

  if (name === 'humana') {
    if ([
      'COMPBENEFITS INSURANCE COMPANY'
    ].includes(orgName as string)) {
      return '';
    }
    return 'https://logo.plans.coverrightmedicare.com/humana.png';
  }

  if (name === 'amerigroup') {
    return 'https://logo.plans.coverrightmedicare.com/wellpoint.svg';
  }

  if (name === 'wellcare') {
    if ([
      'MERIDIAN HEALTH PLAN OF ILLINOIS, INC.',
      'MERIDIAN HEALTH PLAN OF MICHIGAN, INC.',
      'SELECTCARE HEALTH PLANS, INC.',
      'SELECTCARE OF TEXAS, INC.'
    ].includes(orgName as string)) {
      return '';
    }
    return 'https://logo.plans.coverrightmedicare.com/wellcare.png';
  }

  return name ? `https://logo.plans.coverrightmedicare.com/${name}.png` : '';
};

export function getChartCoverageAreas(coverage: MonthlyTotalCost[] | undefined): ICoverage[] {
  const result: ICoverage[] = [];
  let currentMonth = new Date().getMonth() + 1;

  // if this is for full year, show from Jan
  if (coverage?.some(value => value.month === 1)) {
    currentMonth = 0;
  }

  if (!coverage) {
    return [{
      startMonth: 0,
      color: '#fff',
      text: 'Drug Deductible',
      stage: DrugCalculationStage.Deductible,
      split: length => length < 2
    }];
  } else {
    const initial = coverage.find(c => c.stage === DrugCalculationStage.InitialCoverage);
    const gap = coverage.find(c => c.stage === DrugCalculationStage.CoverageGap);
    const catastrophic = coverage.find(c => c.stage === DrugCalculationStage.Catastrophic);

    if (initial) {
      const initialMonth = initial.month - 1;
      if (initialMonth > currentMonth) {
        result.push({
          startMonth: 0,
          color: '#fff',
          text: 'Drug Deductible',
          stage: DrugCalculationStage.Deductible,
          split: length => length < 2
        });
      }
      result.push({
        startMonth: initialMonth - currentMonth,
        color: '#FEF7EB',
        text: 'Initial Coverage',
        stage: DrugCalculationStage.InitialCoverage,
        split: length => length < 2
      });
    }
    if (gap) {
      const gapMonth = gap.month - 1;
      result.push({
        startMonth: gapMonth - currentMonth,
        color: '#F2FBFF',
        text: 'Coverage Gap',
        stage: DrugCalculationStage.CoverageGap,
        split: length => length < 2
      });
    }
    if (catastrophic) {
      const catastrophicMonth = catastrophic.month - 1;
      result.push({
        startMonth: catastrophicMonth - currentMonth,
        color: '#E9F8F5',
        text: 'Catastrophic Coverage',
        stage: DrugCalculationStage.Catastrophic,
        split: length => length < 3
      });
    }
  }
  return result;
};

interface ICoverage {
  startMonth: number,
  color: string,
  text: string,
  stage: DrugCalculationStage,
  split?: (length: number) => boolean
}

export function matchParentOrgName(parentOrgName?: string | null) {
  if (!parentOrgName) {
    return '';
  }

  for (const key in MACompaniesMatches) {
    if (MACompaniesMatches[key].includes(parentOrgName)) {
      return key;
    }
  }

  return '';
}

export const MACompaniesMatches: { [key: string]: string[] } = {
  'aetna': [
    'Aetna Better Health of New Jersey',
    'Aetna Better Health of Ohio (HMO SNP)',
    'Aetna Better Health of Virginia',
    'Aetna Medicare',
    'Allina Health Aetna Medicare'
  ],
  'amerigroup': [
    'Amerigroup',
    'AMERIGROUP',
    'Amerigroup Community Care',
    'AMERIGROUP Community Care',
    'Amerigroup Community Care of New Mexico',
    'Amerigroup Insurance Company',
    'Amerigroup Iowa',
    'Amerigroup Ohio',
    'AMH Health',
    'Wellpoint',
  ],
  'anthem': [
    'AMH Health',
    'Anthem Blue Cross',
    'Anthem Blue Cross and Blue Shield',
    'Anthem Blue Cross Life and Health Insurance Co.',
    'Anthem Blue Cross Partnership Plan',
    "Healthy Blue",
    "Optimum HealthCare",
    "Simply",
    "Freedom Health",
    "HealthSun",
  ],
  'bright': [
    'Bright Health'
  ],
  'careplus': [
    'CarePlus Health Plans, Inc.'
  ],
  'cigna': [
    'Cigna'
  ],
  'clover': [
    'Clover Health'
  ],
  'freedom': [
    'Freedom Health, Inc.'
  ],
  'empire_bcbs': [
    'Empire BlueCross BlueShield'
  ],
  'healthsun': [
    'HealthSun Health Plans, Inc.'
  ],
  'humana': [
    'Humana'
  ],
  'optimum': [
    'Optimum HealthCare, Inc.'
  ],
  'simply': [
    'Simply Healthcare Plans, Inc.'
  ],
  'uhc': [
    'UnitedHealthcare'
  ],
  'wellcare': [
    'Wellcare',
    'WellCare'
  ]
};


export const getMedigapCompanyLogo = (parentOrgName?: string | null, orgName?: string | null): string => {
  if (!parentOrgName) {
    return '';
  }

  if (!orgName) {
    orgName = '';
  }

  if ([
    'Anthem Inc Grp'
  ].includes(parentOrgName)) {
    if ([
      'Amerigroup Ins Co (Anthem)'
    ].includes(orgName as string)) {
      return '';
    }
    if ([
      'Empire Healthchoice Assur Inc (Anthem)'
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/empirebcbs.png';
    }
    return 'https://logo.plans.coverrightmedicare.com/anthem.png';
  }

  if ([
    'BlueCross BlueShield of South Carolina'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/bcbcsc.png';
  }

  if ([
    'CENTENE CORP GRP'
  ].includes(parentOrgName)) {
    if ([
      'AMERICAN PROGRESSIVE LIFE & HEALTH INSURANCE COMPANY OF NEW YORK'
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/wellcare.png';
    }
    if ([
      'HEALTH NET LIFE INSURANCE COMPANY'
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/healthnet.png';
    }
    if ([
      'QualChoice Life and Health Insurance Company, Inc.'
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/qualchoice.png';
    }
    return '';
  }

  if ([
    'ALLSTATE INS GRP'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/allstate.svg';
  }

  if ([
    'CIGNA HLTH GRP'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/cigna.svg';
  }

  if ([
    'Chubb Limited'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/ACE_Med_Supplement_Logo_Blue.png';
  }

  if ([
    'GGC Grp'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/nassau.png';
  }

  if ([
    'American Enterprise Group'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/medico.png';
  }

  if ([
    'AETNA GRP',
    'CVS GRP'
  ].includes(parentOrgName)) {
    if ([
      'American Continental Insurance Company (Aetna)'
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/aetnabrentwood.png';
    }
    if ([
      'Continental Life Insurance Company of Brentwood, Tennessee (Aetna)'
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/aetnabrentwood2.png';
    }
    if ([
      'Accendo Ins Co'
    ].includes(orgName as string)) {
      return '';
    }
    return 'https://logo.plans.coverrightmedicare.com/aetna.png';
  }

  if ([
    'GUARANTEE TRUST GRP'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/gtl.png';
  }

  if ([
    'HUMANA GRP'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/humana.png';
  }

  if ([
    'MUTUAL OF OMAHA GRP'
  ].includes(parentOrgName)) {
    if ([
      'UNITED OF OMAHA LIFE INSURANCE COMPANY'
    ].includes(orgName as string)) {
      return 'https://logo.plans.coverrightmedicare.com/unitedofomaha.png';
    }
    return 'https://logo.plans.coverrightmedicare.com/moo.png';
  }

  if ([
    'SWISS RE GRP'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/Lumico_NFC.png';
  }

  if ([
    'UNITEDHEALTH GRP'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/uhc.png';
  }

  if ([
    'Wellabe'
  ].includes(parentOrgName)) {
    return 'https://logo.plans.coverrightmedicare.com/wellabe-logo.svg';
  }

  return '';
};

export const MGCompaniesMatches: { [key: string]: string[] } = {
  'anthem': [
    'Anthem Inc Grp'
  ],
  'bcbcsc': [
    'BlueCross BlueShield of South Carolina'
  ],
  'wellcare': [
    'CENTENE CORP GRP'
  ],
  'allstate': [
    'ALLSTATE INS GRP'
  ],
  'cigna': [
    'CIGNA HLTH GRP'
  ],
  'ace': [
    'Chubb Limited'
  ],
  'nassau': [
    'GGC Grp'
  ],
  'medico': [
    'American Enterprise Group'
  ],
  'aetna': [
    'AETNA GRP',
    'CVS GRP'
  ],
  'gtl': [
    'GUARANTEE TRUST GRP'
  ],
  'humana': [
    'HUMANA GRP'
  ],
  'moo': [
    'MUTUAL OF OMAHA GRP'
  ],
  'lumico': [
    'SWISS RE GRP'
  ]
};
