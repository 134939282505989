import * as React from 'react';
import {
  Stack,
  Typography,
  Box,
  Collapse, ButtonBase, Grid
} from '@mui/material';
import { IDrugCostBreakdownPlan } from '@coverright/data-access/medicare';
import { RegulatoryVariable } from '@coverright/data-access/types/medicare';
import { getCompanyLogo, GetDefaultPlansYear, useDeviceWidth } from '@coverright/utils';
import { PropsWithChildren, useMemo, useState } from 'react';
import { CompanyLogo, StarRating } from '@coverright/ui/shared';
import { Chart } from '../chart';
import Coverage from '../coverage';
import {
  CoveragePhaseTable, DrugSummary,
  DrugTable
} from '../drug-tables';
import { CaretUp } from '@phosphor-icons/react';
import Filters from '../filters';
import { DrugOffers } from '../drug-offers';
import { DrugCostBreakdownPdfButton } from '../../drug-cost-breakdown-pdf-button';

type Props = {
  plan: IDrugCostBreakdownPlan;
  loading: boolean;
  onFullYearChange: (value: boolean) => void;
  fullYear: boolean;
  regulatory?: RegulatoryVariable;
};

export default function Z1Breakdown(props: Props) {
  const phone = useDeviceWidth('md');
  const { plan, onFullYearChange, fullYear, loading } = props;

  const planYear = (plan?.planYear || GetDefaultPlansYear()).toString().replace('Year', '');
  const [selectedDrugs, setSelectedDrugs] = useState<string[]>([]);

  const costs = useMemo(() => {
    return (
      plan?.drugCostMonthlyBreakdown?.costs
        .filter((d) => !selectedDrugs.length || selectedDrugs.includes(d.rxcui))
        .map((c) => ({
          ...c,
          numericCost:
            plan?.drugPrices.find((d) => d.rxcui === c.rxcui)?.quoteQuantity ||
            0,
          packageName:
            plan?.drugPrices.find((p) => p.rxcui === c.rxcui)
              ?.packageDescription || c.packageName,
        })) || []
    );
  }, [plan, selectedDrugs]);

  const drugPrices = useMemo(() => {
    return (
      plan?.drugPrices.filter(
        (d) => !selectedDrugs.length || selectedDrugs.includes(d.rxcui)
      ) || []
    );
  }, [plan, selectedDrugs]);

  const chartHeight = phone ? 150 + (plan?.drugPrices?.length || 0) * 20 : undefined;

  return (
    <Stack spacing={2}>
      <Box sx={{position: 'absolute', top: 0, right: 50}}>
        <DrugCostBreakdownPdfButton plan={plan} drugPrices={drugPrices} regulatory={props.regulatory} />
      </Box>
      <Section title={'Plan and drugs summary'}>
        <Stack spacing={3}>
          <Box
            sx={{
              display: 'flex',
              alignItems: { sm: 'center' },
              justifyContent: 'space-between',
              flexDirection: { xs: 'column', sm: 'row' },
              border: '1px solid #E5E7EB',
              p: 2,
              borderRadius: '8px',
            }}
          >
            <div>
              <Typography><b>{plan.planName}</b></Typography>
              <StarRating
                plan={plan}
                size={18}
                endAdornment={
                  <Typography sx={{ ml: 1 }} className={'medium fs-12'}>
                    ({planYear})
                  </Typography>
                }
              />
            </div>
            <CompanyLogo
              sx={{ backgroundPosition: 'center' }}
              url={getCompanyLogo(plan.parentOrgName, plan.orgName)}
            />
          </Box>
          <DrugOffers
            drugPrices={plan.drugPrices}
            discountedDrugs={plan.discountedDrugs}
          />
        </Stack>
      </Section>


      <Section title={'Yearly estimation coverage'}>
        <Filters
          title={'Filter by selected drugs and time frame'}
          sx={{'& .dcb-filters-title': {flexGrow: 1, color: 'text.secondary'} }}
          plan={plan}
          selectedDrugs={selectedDrugs}
          onFullYearChange={onFullYearChange}
          fullYear={fullYear}
          onChange={setSelectedDrugs}
        />

        <Box mt={2}>
          <Typography variant={'body2'} sx={{textAlign: 'center', bgcolor: '#E8FDF3', color: 'accent.primary', p: '14px'}}>
            Average monthly cost: <b>{plan?.drugsCost}</b>
          </Typography>
          <Chart
            wrapperSx={{ display: 'block', ml: -1 }}
            height={chartHeight}
            costs={costs}
            monthlyCosts={plan.drugCostMonthlyBreakdown?.monthlyCosts || []}
            colors={[
              '#1E95A0',
              '#1DB399',
              '#C8E8EB',
              '#7DA8AC',
              '#84C7BB',
              '#C3D4D6',
            ]}
            loading={loading}
          />
        </Box>

        <Coverage plan={plan} variant={'z1'} />
      </Section>

      <Section title={'Detailed cost calculations'}>
        <Stack spacing={2}>

          <Typography variant={'h4'}><b>Drugs</b></Typography>

          <DrugTable drugPrices={drugPrices} variant={'z1'} />

          <Typography variant={'h4'}><b>By coverage phases</b></Typography>

          <CoveragePhaseTable drugPrices={drugPrices} variant={'z1'} />

          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant={'body2'}><b>Deductible</b></Typography>
              <Typography variant={'body2'} color={'text.secondary'} sx={{ mb: 2 }}>
                Estimated full retail price<sup>1</sup>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={'body2'}><b>Initial coverage</b></Typography>
              <Typography variant={'body2'} color={'text.secondary'} sx={{ mb: 2 }}>
                Minimum of retail price and plan copay/ coinsurance (based on tier)
                <sup>2</sup>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant={'body2'}><b>Catastrophic coverage</b></Typography>
              <Typography variant={'body2'} color={'text.secondary'}>
                Greater of {props.regulatory?.catastrophicCoinsurance || 0}% or $
                {props.regulatory?.catastrophicCopayGeneric || 0} (generics) / $
                {props.regulatory?.catastrophicCopayBranded || 0} (branded)
                <sup>4</sup>
              </Typography>
            </Grid>
          </Grid>

          <Typography variant={'h4'}><b>Summary by month</b></Typography>

          <DrugSummary
            costs={costs}
            monthlyCosts={plan?.drugCostMonthlyBreakdown?.monthlyCosts}
            total={plan?.drugCostMonthlyBreakdown?.totalClientCost}
          />
        </Stack>
      </Section>
    </Stack>
  );
}

const Section = (props: PropsWithChildren<{title: string}>) => {
  const [expanded, setExpanded] = React.useState(true);

  return <>
    <ButtonBase sx={{display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #E5E7EB', alignItems: 'center', py: 2, pr: 2}}
                onClick={() => setExpanded(!expanded)} >
      <Typography variant={'h3'}><b>{props.title}</b></Typography>
      <Box sx={{transform: expanded ? 'rotate(-180deg) !important' : 'rotate(0)', transition: 'all 0.3s',}}>
        <CaretUp size={18} color={'#1C434F'} />
      </Box>
    </ButtonBase>

    <Collapse in={expanded}>
      {props.children}
    </Collapse>
  </>
}
